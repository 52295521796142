import React, {Component} from 'react';
import NavbarComponent from '../../shared/navbar/components/NavbarComponent';

// import Box from '../../shared/box/components/BoxComponent';

import '../styles/LandingComponents.scss';
import LightBoxComponent from '../../../shared/lightbox/components/LightBoxComponent';
import RegisterModalComponent from '../../../shared/registermodal/components/RegisterModalComponent';

import LoginComponent from '../../../shared/Login/components/LoginComponent';

import OurBrandsComponent from '../../shared/ourBrands/components/OurBrandsComponent';
import OurRetailersComponent from '../../shared/ourRetailers/components/OurRetailersComponent';
// import OurPlatformComponent from '../../shared/ourPlatform/components/OurPlatformComponent';
import Subscribe from '../../shared/subscribe/components/Subscribe';
import FooterComponent from '../../../shared/footer/components/FooterComponent';

import ScrollableAnchor, {configureAnchors} from 'react-scrollable-anchor';
import HighlightA from '../../../shared/highlights/components/highlightA/HighlightA';
import HighlightB from '../../../shared/highlights/components/highlightB/HighlightB';
import axios from 'axios';
import LoadingComponent from '../../../shared/loading/components/LoadingComponent';
import Hero from '../../shared/hero/components/Hero';

import '../../shared/ourPlatform/style/OurPlatform.scss';

import {withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';

class LandingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            showRegister: false,
            registerCompanyType: '',
            landing: [],
            loading: true,
            renderHighlight: '',
            heroFiltrado: false,
            showPoweredByBlanc: false,
        };

        this.closeRegister = this.closeRegister.bind(this);
        this.openRegister = this.openRegister.bind(this);
        this.openLogin = this.openLogin.bind(this);
        this.closeLogin = this.closeLogin.bind(this);
    }

    openLogin(eventUrl = '') {
        if (eventUrl !== '') localStorage.setItem('vincular_evento', `/${eventUrl}`);
        this.setState({
            showLogin: true,
        });
    }

    closeLogin() {
        localStorage.removeItem('redirectBrand');
        localStorage.removeItem('vincular_evento');
        this.setState({
            showLogin: false,
        });
    }

    openRegister(companyType = '', eventUrl = '') {
        if (eventUrl !== '') localStorage.setItem('defaultEvent', `/${eventUrl}`);
        this.setState({
            registerCompanyType: companyType,
            showRegister: true,
        });
    }

    closeRegister() {
        localStorage.removeItem('defaultEvent');
        this.setState({
            showRegister: false,
        });
    }

    chooseHighlight(text) {
        const random = Math.floor(Math.random() * 2) + 1;
        return random % 2 === 0 ? (
            <HighlightA text={text} open={this.openLogin.bind(this)} />
        ) : (
            <HighlightB open={this.openLogin.bind(this)} text={text} />
        );
    }

    async getLanding() {
        let result = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/landing`);
        let response = await result.data;
        let heroFiltrado = false;

        if (this.props.evento) {
            const heroFiltradoEvento = response.hero.filter((item) => {
                if (item.event_segment) {
                    return item.event_segment.id === this.props.evento.id;
                }

                return false;
            });

            if (heroFiltradoEvento.length > 0) {
                response.hero = heroFiltradoEvento;
                heroFiltrado = true;
            }
        }

        if (!heroFiltrado) {
            response.hero = response.hero.filter((item) => item.show_landing_page);
        }

        response.hero = await Promise.all(
            response.hero
                ? response.hero.map(async (item) => {
                      if (item.event_segment && item.event_segment.id) {
                          let resp = await axios.get(
                              `${process.env.REACT_APP_NEW_API_URL}/v1/segment/${item.event_segment.id}`
                          );
                          item.slug = resp.data.slug;
                      }
                      return item;
                  })
                : []
        );

        this.setState({
            landing: response,
            loading: false,
            renderHighlight: this.chooseHighlight(response.spotlight),
            heroFiltrado: heroFiltrado,
        });
    }

    componentDidMount() {
        configureAnchors({
            offset: -120,
            scrollDuration: 1000,
        });
        
        localStorage.removeItem('redirectBrand');

        this.setState({
            showPoweredByBlanc: Number(localStorage.getItem('xAccountPoweredByBlanc')),
        });
        this.getLanding();
    }

    replaceWithBrTag(text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    render() {
        const accountTitle = localStorage.getItem("xAccountTitle");
        const {t} = this.props;
        if (this.state.loading) {
            return <LoadingComponent />;
        }

        let retailers_section;
        if (t('lang') !== 'pt_BR') {
            retailers_section = (
                <>
                    <section id='our-retailers'>
                        <div className='left'>
                            <ScrollableAnchor id={'ourretailers'}>
                                <h3 className='title'>{t('OUR RETAILERS*', 'OUR RETAILERS*')}</h3>
                            </ScrollableAnchor>
                            <p className='description'>
                                {this.replaceWithBrTag(this.state.landing.our_retailers_text)}
                            </p>
                            <div className='buttons'>
                                <button onClick={() => this.openRegister()}>{t('REGISTER NOW', 'REGISTER NOW')}</button>
                            </div>
                        </div>
                        <div className='right'>
                            <div id='our-retailers-slider'>
                                <OurRetailersComponent />
                            </div>
                        </div>
                    </section>
                </>
            );
        }

        return (
            <div id='landing-page'>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>{accountTitle ? accountTitle : "Blanc"}</title>
                    <meta name='keywords' content='collection, brands, fashion, buy, sell, incubator' />
                    <meta http-equiv='content-language' content='en' />
                    <meta
                        name='description'
                        content='BLANC is a digital B2B platform built for the future of the fashion wholesale industry. We enable brands to connect with global retailers and deliver seamless orders, facilitating sustainable growth for their businesses.'
                    />
                </Helmet>
                <NavbarComponent showLogin={() => this.openLogin()} navEvento={this.state.heroFiltrado} />
                {this.state.showRegister && (
                    <RegisterModalComponent companyType={this.state.registerCompanyType} close={this.closeRegister} />
                )}

                {this.state.showLogin && (
                    <LightBoxComponent
                        isOpen={this.state.showLogin}
                        handleClick={() => this.closeLogin()}
                        handleEventClose={() => this.closeLogin()}>
                        <LoginComponent
                            closeRegister={this.closeRegister}
                            OpenRegister={this.openRegister.bind(this)}
                            evento={this.props.evento}
                        />
                    </LightBoxComponent>
                )}
                {this.state.landing && (
                    <Hero
                        open={this.openLogin.bind(this)}
                        openRegister={this.openRegister.bind(this)}
                        data={this.state.landing.hero}
                        eventPage={this.state.heroFiltrado}
                    />
                )}
                {this.state.heroFiltrado && (
                    <div className='container'>
                        <section id='about-the-project'>
                            <ScrollableAnchor id={'abouttheproject'}>
                                <h3 className='title'>
                                    {this.state.landing.hero[0].event_segment_title_about_project ||
                                        'ABOUT THE PROJECT*'}
                                </h3>
                            </ScrollableAnchor>
                            <p
                                className='description'
                                dangerouslySetInnerHTML={{
                                    __html: this.replaceWithBrTag(
                                        this.state.landing.hero[0].event_segment_about_project || ''
                                    ),
                                }}></p>
                        </section>
                        <section id='our-brands-top'>
                            <ScrollableAnchor id={'ourbrands'}>
                                <h3 className='title'>{t('OUR BRANDS*', 'OUR BRANDS*')}</h3>
                            </ScrollableAnchor>
                            <p className='description'></p>
                        </section>
                        <section id='our-brands' className='filtrado'>
                            <OurBrandsComponent
                                openLogin={this.openLogin}
                                openRegister={this.openRegister}
                                eventSegment={this.props.evento}
                                marginRegular={true}
                            />
                        </section>
                    </div>
                )}
                {!this.state.heroFiltrado && !this.state.showPoweredByBlanc && (
                    <div className='container'>
                        <section id='our-platform'>
                            <div className='left'>
                                <ScrollableAnchor id='ourplatform'>
                                    <h3 className='title'>
                                        <span>{t('navbar_item_2_uc', 'Our Platform')}*</span>
                                    </h3>
                                </ScrollableAnchor>
                                <p
                                    className='description'
                                    dangerouslySetInnerHTML={{
                                        __html: this.replaceWithBrTag(this.state.landing.our_platform_text) || '',
                                    }}
                                />
                                <h1 className='title'>{t('*REGISTER NOW', '*REGISTER NOW')}</h1>
                                <div className='buttons'>
                                    <button onClick={() => this.openRegister('s')}>
                                        {t("I'm a brand", "I'm a brand")}
                                    </button>
                                    <button onClick={() => this.openRegister('b')}>
                                        {t("I'm a buyer", "I'm a buyer")}
                                    </button>
                                </div>
                            </div>
                            <div className='right'>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/landing/ourplatform.png`}
                                    alt='Our platform'
                                />
                            </div>
                        </section>
                        <section id='our-brands'>
                            <div className='left'>
                                <ScrollableAnchor id={'ourbrands'}>
                                    <h3 className='title'>{t('OUR BRANDS*', 'OUR BRANDS*')}</h3>
                                </ScrollableAnchor>
                                <p className='description'>{this.state.landing.our_brands_text || ''}</p>
                                <div className='buttons'>
                                    <button onClick={() => this.openRegister()}>
                                        {t('REGISTER NOW', 'REGISTER NOW')}
                                    </button>
                                </div>
                            </div>
                            <div className='right'>
                                <OurBrandsComponent openLogin={this.openLogin} openRegister={this.openRegister} />
                            </div>
                        </section>
                        <div id='divider'></div>
                        {retailers_section}
                        <section id='request-a-demo'>
                            <div className='left'>
                                <h1 className='title'>{t('REQUEST A DEMO*', 'REQUEST A DEMO*')}</h1>
                                <p className='description'>
                                    {this.state.landing.request_a_demo_text ||
                                        'Request a booking with one of the BLANC sales team to show you how our platform can be tailored for your business to improve your wholesale today.'}
                                </p>
                                <div className='buttons'>
                                    <button onClick={() => this.openRegister('s')}>
                                        {t("I'm a brand", "I'm a brand")}
                                    </button>
                                    <button onClick={() => this.openRegister('b')}>
                                        {t("I'm a buyer", "I'm a buyer")}
                                    </button>
                                </div>
                            </div>
                            <div className='right'>
                                <div className='img-computer'>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/whoweare/note.png`}
                                        alt='Our platform'
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                )}
                {!this.state.heroFiltrado && !this.state.showPoweredByBlanc && (
                    <Subscribe text={this.state.landing.subscribe} />
                )}
                <FooterComponent hideInfos={this.state.heroFiltrado} openRegister={this.openRegister} />
            </div>
        );
    }
}

export default withTranslation()(LandingComponent);
