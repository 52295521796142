import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuComponent from "../../menu/components/MenuComponent";
import SpinnerComponent from "../../shared/spinner";
import "../../cart/styles/CartViewComponent.scss";
import { connect } from "react-redux";
import CartInterector from "../../../domain/pages/cart/CartInterector";
import CartService from "../../../services/CartService";
import CartServiceRedux from "../../../data/pages/cart/CartService";
import FeedBackMsgComponents from "../../shared/feedbackmsg/components/FeedBackMsgComponents";
import ReactTooltip from "react-tooltip";
import ProductSizeChart from "../../shared/productSizeChart/ProductSizeChart";
import {
  getCurrentPriceByPriceList,
  GetVariationColorStyle,
} from "../../../utils/productHelper.js";
import mixpanel from "mixpanel-browser";
import isMobile from "is-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import UpdateAddressModal from "./Cart/UpdateAddressModal";
import Swal from "sweetalert2";
import { generatePath } from "react-router";
import { Translation, withTranslation } from "react-i18next";
import LightboxHalfScreenComponent from "../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";
import axios from "axios";
import { saveAs } from 'file-saver'
import checkIcon from '../../../imgs/check.svg'
import alertIcon from '../../../imgs/alert.svg'
// t("MAXIMUM", "MAXIMUM")
// t("MINIMUM", "MINIMUM")
const CartEmpity = () => {
  return (
    <Translation>
      {(t) => (
        <div className="cartEmpity">
          <FontAwesomeIcon icon={faShoppingCart} />
          <h1>
            {t("YOU CART IS EMPTY", "YOU CART IS EMPTY")}. <br />
            {t("ADD SOME PRODUCTS TO BEGIN", "ADD SOME PRODUCTS TO BEGIN")}.
          </h1>
          <Link to="/brands">
            <div className="linkBrands">
              {t("find new brands", "find new brands")}
            </div>
          </Link>
        </div>
      )}
    </Translation>
  );
};

class CartViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedback: false,
      showModalUpdateAddress: false,
      showErrorFeedback: false,
      valueError: false,
      quantityError: false,
      cartList: false,
      cart: null,
      isLoading: true,
      sellerSelected: {
        sellerSelected: {
          name: "",
        },
      },
      visibleNotes: [],
      productsNotes: [],
      productsOnTheOrder: [],
      productVariations: [],
      cartService: new CartService(),
      cartItems: [],
      cartSidebar: [],
      message: "",
      lastAddedId: null,
      sendOrderLoading: false,
      cartEmpty: false,
      showLightBoxSize: false,
      infoSizeChart: {},
      errors: [],
      collectionsErrors: [],
      productsErrors: [],
      sellerErrors: [],
      nextPage: false,
      isLoadingMore: false,
      loadingAutofilll: false,
      collectionPricelists: [],
      isLoadingCollectionPricelists: false,
      showModalImport: false,
      showModalReplaceCart: false,
      loadingExport: false,
      showModalGoToCart: false,
      loadingImport: false,
      loadingReplace: false,
      loadingAddToCart: false,
      totalProducts: 0,
      purchaseOrderDraft: [],
      cartId: "",
      importFile: "",
      operationWord: 'created',
      orderErrorState: []
    };

    this.timer = null;
    this.timeoutMap = {};
    this.closeFeedback = this.closeFeedback.bind(this);
  }

  componentDidMount() {
    this.getCart(true);
  }

  getCart(replaceAll = false) {
    this.state.cartService.getCart().then((res) => {
      if (res.data.results.length === 0) {
        this.setState({
          cartEmpty: true,
          isLoading: false,
        });
      } else {
        this.cartData(res, replaceAll);
        this.setState({
          nextPage: res.data.pagination.next,
        });

        if (this.props.location.state) {
          const { sellerId } = this.props.location.state;
          if (sellerId) {
            this.sellerSelect(sellerId);
            this.setState({ lastAddedId: sellerId });
          }
        }
      }
    });
  }

  getMoreCarts() {
    if (!this.state.nextPage) {
      return;
    }

    this.setState({ isLoadingMore: true });
    this.state.cartService.getCart(this.state.nextPage).then((res) => {
      this.cartData(res);
      this.setState({
        isloading: false,
        nextPage: res.pagination.next,
      });
    });
  }

  getPricelists(cartId) {
    this.setState({
      isLoadingCollectionPricelists: true,
    });

    if (!cartId) {
      return this.setState({
        isLoadingCollectionPricelists: false,
        isLoading: false,
        collectionPricelists: [],
      });
    }

    this.state.cartService
      .getPricelists(cartId)
      .then((response) => {
        this.setState({
          isLoadingCollectionPricelists: false,
          isLoading: false,
          collectionPricelists: response.data.data,
        })
      })
  }

  removeAllProducts() {
    const { t } = this.props;
    var confirmRemove = window.confirm(
      t(
        "Do you want to remove all products of this brand?",
        "Do you want to remove all products of this brand?"
      )
    );
    if (confirmRemove) {
      this.setState({ isLoading: true });

      const sameSellerOtherCart = this.state.cartItems.find(
        (item) => item.seller.id === this.state.sellerSelected.seller.id
      );

      new CartInterector(
        new CartServiceRedux()
          .removeProductsAll(this.state.sellerSelected.cartId)
          .then((res) => {
            this.setState(
              {
                cart: null,
                cartItems: [],
              },
              () => {
                if (sameSellerOtherCart) {
                  this.updateCartPath(sameSellerOtherCart.cartId);
                }
                this.getCart(true);
              }
            );
          })
      );
    }
  }

  removeProductFromCart(productId, cartId) {
    const { t } = this.props;
    let confirmRemove = window.confirm(
      t(
        "Are you sure you want to remove this product from cart?",
        "Are you sure you want to remove this product from cart?"
      )
    );

    if (confirmRemove) {
      this.setState({
        isLoading: true,
        errors: [],
      });

      new CartInterector(
        new CartServiceRedux().removeCart(cartId, productId).then((res) => {
          this.getCart(true);
        })
      );
    }
  }

  cartData(data, replaceAll = false) {
    let response = data;
    let cartItems = [];

    data.data.results.forEach((cartItem, index) => {
      cartItems.push({
        cartId: cartItem.id,
        collections: [],
        status: cartItem.status,
        buyer: cartItem.buyer,
        orderDeliveryDays: cartItem.order_delivery_days,
        pricelist: cartItem.pricelist,
        seller: {
          id: cartItem.seller.id,
          name: cartItem.seller.name,
          linesheetsCount: cartItem.linesheets.count,
        },
      });

      cartItem.collections.forEach((collection, collectionIndex) => {
        const collectionData = {
          collection: {
            id: collection.collection.id,
            name: collection.collection.name,
          },
          products: [],
        };

        collection.products.forEach((product) => {
          let variations_list = [];

          Object.keys(product.variations).forEach((key) => {
            variations_list[key] = product.variations[key];
          });

          collectionData.products.push({
            id: product.product.id,
            cartId: cartItem.id,
            name: product.product.name,
            image: product.product.image.picture_thumbnail,
            deliveryDays: product.product.order_delivery_days,
            referenceCode: product.product.reference_code,
            pricelists: product.product.pricelists,
            note: product.product.note,
            wholesalePrice: {
              currency: product.product.wholesale_price.currency,
              amount: product.product.wholesale_price.amount,
              tip: product.product.wholesale_price_tip,
            },
            retailerPrice: {
              currency: product.product.retailer_price.currency,
              amount: product.product.retailer_price.amount,
              tip: product.product.retailer_price_tip,
            },
            validationsRules: {
              orderMaximumQuantity:
                product.product.order_maximum_quantity,
              orderMinimumQuantity:
                product.product.order_minimum_quantity,
            },
            variations: variations_list,
            errors: [],
            errorsSizes: [],
            sizeChart: product.product.sizechart,
            ship_msg: product.product.ship_msg,
          });
        });

        cartItems[index].collections.push(collectionData);
      });
    });

    let newCartItems = [];

    if (replaceAll) {
      newCartItems = [...cartItems];
    } else {
      newCartItems = [...this.state.cartItems, ...cartItems];
    }

    const cartSidebar = [];

    for (let index = 0; index < cartItems.length; index++) {
      const element = cartItems[index];

      const sellerId = String(element.seller ? element.seller.id : "empty");

      if (!cartSidebar[sellerId]) {
        cartSidebar[sellerId] = [];
      }

      cartSidebar[sellerId].push(element);
    }

    let newCartSidebar = [];

    if (replaceAll) {
      newCartSidebar = [...cartSidebar];
    } else {
      newCartSidebar = [...this.state.cartSidebar, ...cartSidebar];
    }

    let sellerSelected = { ...newCartItems[0] };

    if (this.props.match.params.cartId) {
      let cartSelectedId = newCartItems.find(
        (item) => item.cartId === Number(this.props.match.params.cartId)
      );

      if (cartSelectedId) {
        sellerSelected = { ...cartSelectedId };
      }
    }

    this.updateCartPath(sellerSelected.cartId);

    this.setState({
      cartItems: newCartItems,
      cartSidebar: newCartSidebar,
      cart: response.data,
      sellerSelected: sellerSelected,
      totalProducts: sellerSelected.collections.reduce((total, collection) => {
        return total + collection.products.length;
      }, 0),
      visibleNotes: sellerSelected.collections.flatMap((collection) =>
        collection.products.map((product, index) => index)
      ),
    });
    const url = window.location.href
    const regex = /\/cart\/(\d+)/;
    const match = url.match(regex);
    const numbers = match ? match[1] : null;

    this.getPricelists(numbers);
  }



  isOrderValid() {
    const { t } = this.props;
    let validFlag = true,
      sellerSelected = this.state.sellerSelected;
    let errors = [];

    sellerSelected.collections.forEach((collection, collectionIndex) => {
      collection.products.forEach((product, index) => {
        // product.errors = [];
        if (
          product.validationsRules.orderMaximumQuantity && product.validationsRules.orderMaximumQuantity >= 0 &&
          this.totalizersCalc(index, collectionIndex).totalUnits >
          product.validationsRules.orderMaximumQuantity
        ) {
          validFlag = false;
          errors.push({
            index: index,
            collectionIndex: collectionIndex,
            error: (
              <React.Fragment>
                <h1>{t("MAXIMUM QUANTITY", "MAXIMUM QUANTITY")}</h1>
                {t("THE MAXIMUM QUANTITY IS", "THE MAXIMUM QUANTITY IS")}{" "}
                <b>{product.validationsRules.orderMaximumQuantity}</b> FOR THIS
                {t("ORDER TO PROCEED", "ORDER TO PROCEED")}
              </React.Fragment>
            ),
          });
        }

        let totalProduct = 0;
        product.variations.map((variation) => {
          variation.items.map((item) => {
            totalProduct += item.quantity
          })
        })

        if (totalProduct > 0 &&
          this.totalizersCalc(index, collectionIndex).totalUnits <
          product.validationsRules.orderMinimumQuantity
        ) {
          validFlag = false;
          errors.push({
            index: index,
            collectionIndex: collectionIndex,
            error: (
              <React.Fragment>
                <h1>{t("MINIMUM QUANTITY", "MINIMUM QUANTITY")}</h1>
                {t("THE MINIMUM QUANTITY IS", "THE MINIMUM QUANTITY IS")}{" "}
                <b>{product.validationsRules.orderMinimumQuantity}</b>{" "}
                {t("FOR THIS ORDER TO PROCEED", "FOR THIS ORDER TO PROCEED")}
              </React.Fragment>
            ),
          });
        }
      });
    });
    if (!validFlag) {
      setTimeout(() => {
        var element = document.getElementsByClassName("box-error")[0];

        if (element) {
          element.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          // const offset = 45;
          // const bodyRect = document.body.getBoundingClientRect().top;
          // const elementRect = element.getBoundingClientRect().top;
          // const elementPosition = elementRect - bodyRect;
          // const offsetPosition = elementPosition - offset;

          // window.scrollTo({
          //   top: offsetPosition,
          //   behavior: "smooth",
          // });
        }
      }, 300);
    }

    this.setState({ sellerSelected });
    this.setState({ errors });
    this.setState({ showErrorFeedback: !validFlag });

    return validFlag;
  }
  handleImportInput(e) {
    e.target.parentElement.setAttribute('data-text', e.target.files[0].name);
    this.setState({
      importFile: e.target.files[0]
    })
  }

  exportXls() {
    this.setState({
      loadingExport: true
    })
    axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/cart-export?pricelist_id=${parseInt(this.state.sellerSelected.pricelist !== null ? this.state.sellerSelected.pricelist.id : 0)}&cart_id=${this.state.sellerSelected.cartId}`, {
      responseType: 'blob'
    }).then((res) => {
      saveAs(res.data, `${this.state.sellerSelected.seller.name}.xlsx`)
      this.setState({
        loadingExport: false
      })
    });
  }

  sendImport(e) {
    e.preventDefault();
    this.setState({ loadingImport: true })
    const form = new FormData()
    form.append('seller_id', this.state.sellerSelected.seller.id)
    form.append('file', this.state.importFile)

    axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/pre-import`, form).then(({ data }) => {

      if (data.products_not_found.length > 0) {
        Swal.fire({
          title: "Products not found",
          text: `Products not found in collection`,
          icon: "error",
          confirmButtonColor: "#000"
        })
        this.setState({ loadingImport: false })
        return
      }
      if (data.products_variation_or_size_not_found.length > 0) {
        Swal.fire({
          title: "Products variation or size not found",
          text: `Products variation or size not found in collection`,
          icon: "error",
          confirmButtonColor: "#000"
        })
        this.setState({ loadingImport: false })
        return
      }
      if (data.products_with_no_size.length > 0) {
        Swal.fire({
          title: "Products with no size",
          text: `Products without size in the system`,
          icon: "error",
          confirmButtonColor: "#000"
        })
        this.setState({ loadingImport: false })
        return
      }

      this.setState({ purchaseOrderDraft: Object.values(data.purchase_order_drafts) })

      if (data.has_opened_cart) {
        this.setState({
          loadingImport: false,
          showModalImport: false,
          showModalReplaceCart: true
        })
      } else {
        axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/confirm-import`, data.purchase_order_drafts).then(({ data }) => {
          this.setState({
            loadingImport: false,
            operationWord: 'created',
            cartId: data.data.id,
            showModalImport: false,
            showModalGoToCart: true
          })
        })
          .catch((error) => {
            this.setState({
              loadingImport: false,
            })
            Swal.fire({
              title: "Error",
              text: error.response.data.cartProducts,
              icon: "error",
              confirmButtonColor: "#000"
            })
          })
      }

    }).catch((error) => {
      if (error.response.data.file) {
        Swal.fire({
          title: "Invalid extension!",
          text: 'Only XLSX and XLS files are accepted.',
          icon: "error",
          confirmButtonColor: "#000"
        })
      } else {
        Swal.fire({
          title: "Invalid file!",
          text: 'The uploaded file is invalid.',
          icon: "error",
          confirmButtonColor: "#000"
        })
      }
      this.setState({ loadingImport: false })
    })
  }

  sendReplace(replace = true) {
    if (replace) {
      this.setState({ loadingReplace: true })
      axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/confirm-import`, {
        purchase_order_drafts: this.state.purchaseOrderDraft,
        create_new_cart: true
      }).then(({ data }) => {
        this.setState({
          loadingReplace: false,
          operationWord: 'replaced',
          cartId: data.data.id,
          showModalReplaceCart: false,
          showModalGoToCart: true
        })
      })
        .catch((error) => {
          this.setState({
            loadingReplace: false,
          })
          Swal.fire({
            title: "Error",
            text: error.response.data.cartProducts,
            icon: "error",
            confirmButtonColor: "#000"
          })
        })
    } else {
      this.setState({ loadingAddToCart: true })
      axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/confirm-import`, {
        purchase_order_drafts: this.state.purchaseOrderDraft,
        create_new_cart: false
      }).then(({ data }) => {
        this.setState({
          loadingAddToCart: false,
          operationWord: 'updated',
          cartId: data.data.id,
          showModalReplaceCart: false,
          showModalGoToCart: true
        })
      })
        .catch((error) => {
          this.setState({
            loadingAddToCart: false,
          })
          Swal.fire({
            title: "Error",
            text: error.response.data.cartProducts,
            icon: "error",
            confirmButtonColor: "#000"
          })
        })
    }
  }

  finalizeOrderCart() {
    const { t, i18n } = this.props;
    this.setState({ quantityError: false });
    this.setState({ valueError: false });

    this.setState({
      sendOrderLoading: true,
    });

    const cartService = new CartServiceRedux();
    const cartInterector = new CartInterector(cartService);

    cartService.finalizeOrder(this.state.sellerSelected.cartId, this.state.message, true)
        .then((res) => {
          this.setState({
            errorsSizes: [],
            collectionsErrors: [],
            productsErrors: [],
            sellerErrors: [],
            errors: [],
          });

          if (res.status === 400) {
            this.setState({ sendOrderLoading: false });

            if (res.data.message === t("Your cart is empty.", "Your cart is empty.")) {
              let error = (
                <React.Fragment>
                  <b>{t("YOUR CART IS EMPTY", "YOUR CART IS EMPTY")}</b>
                </React.Fragment>
              );

              this.setState({ valueError: error });
            }

            else if (res.data.message === t("Unfortunately, we were unable to process your order. Please get in touch with our support team.", "Unfortunately, we were unable to process your order. Please get in touch with our support team.")) {
              let error = (
                <React.Fragment>
                  <b>{t(res.data.message, res.data.message)}</b>
                </React.Fragment>
              );

              this.setState({ valueError: error });
            }

            if (res.data.collections) {
              let ListCollectionsErrors = [];

              res.data.collections.map((singleError) => {
                let errorMessage = '';

                if (singleError.quantity != null) {
                  errorMessage = (
                    <React.Fragment>
                      <img alt="" src={alertIcon} />{" "}
                      {t("THE", "THE")}{" "}
                      {singleError.quantity.side === "minimum"
                        ? "MINIMUM"
                        : "MAXIMUM"}{" "}
                      {t("QUANTITY IS", "QUANTITY IS")}{" "}
                      <b>{singleError.quantity.limit}</b>{" "}
                      {t(
                        "FOR THIS ORDER TO PROCEED",
                        "FOR THIS ORDER TO PROCEED"
                      )}
                    </React.Fragment>
                  );

                  ListCollectionsErrors.push({
                    collection_id: singleError.id,
                    error: errorMessage,
                  });
                }

                if (singleError.value != null) {
                  errorMessage = (
                    <React.Fragment>
                      <img alt="" src={alertIcon} />{" "}
                      {singleError.value.side === "minimum"
                        ? "Add"
                        : "Remove"}
                      {' $'}
                      {(singleError.value.limit - this.totalizersCalcByCollectionId(singleError.id)).toString().replace('-', '')}{" "}
                      {singleError.value.side === "minimum"
                        ? "more to meet the minimum order."
                        : "more to meet the maximum order."}
                    </React.Fragment>
                  );

                  ListCollectionsErrors.push({
                    collection_id: singleError.id,
                    error: errorMessage,
                  });
                }
              });

              this.setState({ collectionsErrors: ListCollectionsErrors });
            }

            if (res.data.seller) {
              let errorSellerMessage = '';
              let listSellerErrors = [];

              if (res.data.seller.quantity != null) {
                errorSellerMessage = (
                  <React.Fragment>
                    <img alt="" src={alertIcon} />{" "}
                    {t("THE", "THE")}{" "}
                    {res.data.seller.quantity.side === "minimum"
                      ? "MINIMUM"
                      : "MAXIMUM"}{" "}
                    {t("QUANTITY IS", "QUANTITY IS")}{" "}
                    <b>{res.data.seller.quantity.limit}</b>{" "}
                    {t(
                      "FOR THIS ORDER TO PROCEED",
                      "FOR THIS ORDER TO PROCEED"
                    )}
                  </React.Fragment>
                );

                listSellerErrors.push({
                  error: errorSellerMessage,
                });
              } else if (res.data.seller.value != null) {
                errorSellerMessage = (
                  <React.Fragment>
                    <img alt="" src={alertIcon} />{" "}
                    {res.data.seller.value.side === "minimum"
                      ? "Add"
                      : "Remove"}
                    {' $'}
                    {(res.data.seller.value.limit - parseFloat(this.generalTotalizersCalc().allValues.replace('$', ''))).toString().replace('-', '')}{" "}
                    {res.data.seller.value.side === "minimum"
                      ? "more to meet the minimum order."
                      : "more to meet the maximum order."}
                  </React.Fragment>
                );

                listSellerErrors.push({
                  error: errorSellerMessage,
                });
              }

              this.setState({ sellerErrors: listSellerErrors });
            }

            const variationError = Object.keys(res.data).filter((item) => item !== "value" && item !== "quantity")
            if(variationError.length > 0){
              this.orderErrorState = res.data
            }

            if (res.data.products && Object.keys(res.data.products).length > 0) {
              const listProductsErrors = [];

              Object.keys(res.data.products).forEach((productKey) => {
                res.data.products[productKey].forEach((product) => {
                  if (product.id) {
                    listProductsErrors.push({
                      id: product.id,
                      error:
                        product.side === "minimum" ? (
                          <React.Fragment>
                            {t("THE MINIMUM QUANTITY IS", "THE MINIMUM QUANTITY IS")}{" "}
                            <b>{product.limit}</b>{" "}
                            {t("FOR THIS ORDER TO PROCEED", "FOR THIS ORDER TO PROCEED")}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {t("THE MAXIMUM QUANTITY IS", "THE MAXIMUM QUANTITY IS")}{" "}
                            <b>{product.limit}</b>{" "}
                            {t("FOR THIS ORDER TO PROCEED", "FOR THIS ORDER TO PROCEED")}
                          </React.Fragment>
                        ),
                    });
                  }
                });
              });

              this.setState({ productsErrors: listProductsErrors });
            }

            this.setState({ showErrorFeedback: true });
            let el = document.getElementsByClassName("error-collection-validation")[0];
            let elementPosition = el.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: elementPosition - 120,
              behavior: "smooth"
            });
          } else if (res.status === 200) {
            cartService.finalizeOrder(this.state.sellerSelected.cartId, this.state.message, false)
                .then((res) => {
                  this.setState({ sendOrderLoading: false });
                  if (res.status === 201) {
                    let sellerSelected = this.state.sellerSelected;
                    sellerSelected.orderId = res.data.order;
                    sellerSelected.orderCheckoutUrl = res.data.checkout_url;
                    sellerSelected.status = "c";

                    this.setState({
                      sellerSelected: sellerSelected,
                    });

                    if (
                      process.env.REACT_APP_MIXPANEL_ENABLED === true ||
                      process.env.REACT_APP_MIXPANEL_ENABLED === "true"
                    ) {
                      if(window.localStorage.getItem("userData")) {
                        mixpanel.identify(
                          `${JSON.parse(window.localStorage.getItem("userData")).id}`
                        );
                      }
                      mixpanel.track("New Order", {
                        seller: this.state.sellerSelected.seller.name,
                        seller_id: this.state.sellerSelected.seller.id,
                        buyer: this.state.sellerSelected.buyer.name,
                        buyer_id: this.state.sellerSelected.buyer.id,
                        order_products: this.state.cart.results[0].products
                          .map((item) => item.product.name)
                          .join(","),
                        order_products_id: this.state.cart.results[0].products
                          .map((item) => item.product.id)
                          .join(","),
                        order_total: this.totalizersCalc(0).totalValue,
                      });
                    }

                    this.showModalUpdateAddress();
                    this.showFeedback();
                  }
                })
                .catch((reason) => {
                  this.setState({ sendOrderLoading: false });
                });
          }
        })
        .catch((reason) => {
          this.setState({ sendOrderLoading: false });
        });
  }

  showModalUpdateAddress() {
    this.setState({
      showModalUpdateAddress: true,
    });
  }

  updateCartPath(cartId) {
    const path = generatePath(this.props.match.path, {
      cartId: cartId,
    });
    this.props.history.replace(path);
  }

  cartSelect(id) {
    let selectedSeller = null;
    selectedSeller = this.state.cartItems.find((item) => item.cartId === id);

    this.setState({ message: "" });

    if (selectedSeller) {
      this.setState({
        sellerSelected: selectedSeller,
        visibleNotes: selectedSeller.collections.flatMap((collection) => {
          collection.products.map((item, index) => index)
        }),
      });
      this.updateCartPath(selectedSeller.cartId);
      this.getPricelists(selectedSeller.cartId);
    } else if (this.state.cartItems[0]) {
      const cartItem = {
        ...this.state.cartItems.find(
          (item) =>
            item.collection &&
            item.collection.id === this.state.cartItems[0].collection.id
        ),
      };
      this.updateCartPath(cartItem.cartId);
      this.setState({
        sellerSelected: cartItem,
        visibleNotes: cartItem.products.map((item, index) => index),
      });
      this.getPricelists(cartItem.cartId);
    } else {
      this.setState({
        sellerSelected: {
          sellerSelected: {
            name: "",
          },
        },
      });
      this.getPricelists(null);
    }
    this.setState({
      cartList: false,
      errors: [],
      errorsSizes: [],
      collectionsErrors: [],
      productsErrors: [],
      sellerErrors: [],
      showErrorFeedback: false,
      showFeedback: false,
      quantityError: false,
      valueError: false,
      cartEmpty: false,
    });

    if (isMobile()) window.scrollTo(0, 0);
  }

  sellerSelect(id) {
    let selectedSeller = this.state.cartItems.find(
      (item) => item.seller.id === id
    );
    this.setState({ message: "" });

    if (selectedSeller) {
      this.setState({
        sellerSelected: selectedSeller,
        visibleNotes: selectedSeller.collections.flatMap((collection) => {
          collection.products.map((item, index) => index)
        }),
      });
      this.updateCartPath(selectedSeller.cartId);
      this.getPricelists(selectedSeller.cartId);
    } else if (this.state.cartItems[0]) {
      const cartSelected = this.state.cartItems.find(
        (item) => item.seller.id === this.state.cartItems[0].seller.id
      );
      this.setState({
        sellerSelected: cartSelected,
        visibleNotes: cartSelected.products.map((item, index) => index),
      });
      this.updateCartPath(cartSelected.cartId);
      this.getPricelists(cartSelected.cartId);
    } else {
      this.setState({
        sellerSelected: {
          sellerSelected: {
            name: "",
          },
        },
      });
      this.getPricelists(null);
    }
    this.setState({
      cartList: false,
      errors: [],
      errorsSizes: [],
      showErrorFeedback: false,
      showFeedback: false,
      quantityError: false,
      valueError: false,
      cartEmpty: false,
    });

    if (isMobile()) window.scrollTo(0, 0);
  }

  totalizersCalcByCollectionId(collectionId) {
    let totalValue = 0.0;

    if (this.state.sellerSelected) {
      const collection = this.state.sellerSelected.collections.find(
        (col) => col.collection.id === collectionId
      );

      if (collection) {
        collection.products.forEach((product) => {
          let totalUnits = 0;

          product.variations.forEach((variation) => {
            variation.items.forEach((item) => {
              totalUnits += item.quantity;
            });
          });

          const currentPrice = getCurrentPriceByPriceList(
            product,
            this.state.sellerSelected.pricelist !== null ? this.state.sellerSelected.pricelist.id : 0
          ).wholesale_price.amount;

          totalValue += totalUnits * parseFloat(currentPrice);
        });
      }
    }
    return totalValue;
  }

  totalizersCalc(index, collectionIndex) {
    let totalUnits = 0,
      totalValue = 0.0;

    if (this.state.sellerSelected) {
      this.state.sellerSelected.collections[collectionIndex].products[index].variations.forEach(
        (variation) => {
          variation.items.forEach((item) => {
            totalUnits += item.quantity;
          });
        }
      );
    }

    totalValue =
      totalUnits *
      parseFloat(
        getCurrentPriceByPriceList(
          this.state.sellerSelected.collections[collectionIndex].products[index],
          this.state.sellerSelected.pricelist !== null ? this.state.sellerSelected.pricelist.id : 0
        ).wholesale_price.amount
      );

    let currency = getCurrentPriceByPriceList(
      this.state.sellerSelected.collections[collectionIndex].products[index],
      this.state.sellerSelected.pricelist !== null ? this.state.sellerSelected.pricelist.id : 0
    ).wholesale_price.currency;

    return {
      totalUnits,
      totalValue: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: currency,
      })
        .format(totalValue)
        .replace("US$", "USD"),
    };
  }

  countVariationTotalUnit(variationItems) {
    let variationTotal = 0;
    variationItems.forEach((variation) => {
      variationTotal += variation.quantity;
    });

    return variationTotal;
  }

  generalTotalizersCalc() {
    let totalAllUnits = 0,
      totalAllValue = 0,
      allValuesToReduce = [];

    this.state.sellerSelected.collections.forEach((collection) => {
      collection.products.forEach((product) => {
        let totalVariationUnits = 0;

        product.variations.forEach((variation) =>
          variation.items.forEach((item) => {
            totalAllUnits += item.quantity;
            totalVariationUnits += item.quantity;
          })
        );

        allValuesToReduce.push(
          getCurrentPriceByPriceList(
            product,
            this.state.sellerSelected.pricelist !== null
              ? this.state.sellerSelected.pricelist.id
              : 0
          ).wholesale_price.amount * totalVariationUnits
        );
      });
    });

    totalAllValue = allValuesToReduce.reduce((prev, current) => prev + current, 0);

    let currency =
      this.state.sellerSelected.collections.length > 0 &&
      this.state.sellerSelected.collections[0].products.length > 0
        ? getCurrentPriceByPriceList(
            this.state.sellerSelected.collections[0].products[0],
            this.state.sellerSelected.pricelist !== null
              ? this.state.sellerSelected.pricelist.id
              : 0
          ).wholesale_price.currency
        : "";

    return {
      totalAllUnits,
      allValues: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: currency,
      }).format(totalAllValue),
    };
  }

  toggleButtonSend(quantity, min_quantity, max_quantity) {
    let buttonSend = document.getElementsByClassName("send-order-btn")[0];
    let buttonSend1 = document.getElementsByClassName("send-order-btn")[1];

    if (quantity < min_quantity || quantity > max_quantity) {
      buttonSend.classList.add("disabled");
      if (buttonSend1)
        buttonSend1.classList.add("disabled");
    } else {
      buttonSend.classList.remove("disabled");
      if (buttonSend1)
        buttonSend1.classList.remove("disabled");
    }
  }

  incrementQuantity(event, size, collectionIndex, quantity, totalProductsItens, stock) {
    let bgSize = event.target.closest(".size");

    bgSize.classList.add("blink");

    if (this.time) {
      clearTimeout(this.time);
    }

    this.time = setTimeout(() => {
      bgSize.classList.remove("blink");
    }, 200);

    if (stock && size.quantity >= stock.qtty_stock) {
      let limit_alert = Array.from(bgSize.children).find((k) =>
        k.classList.contains("limit-alert")
      );
      // let limit_alert =  event.target.closest('.limit-alert');
      limit_alert.classList.remove("hidden");
      setTimeout(() => {
        limit_alert.classList.add("hidden");
      }, 5000);
      return;
    }

    let state = this.state;
    state.sellerSelected.collections[collectionIndex].products.forEach((product) => {
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          if (item.id === size.id) item.quantity += 1;
        })
      );
    });
    this.state.cartService.addItem(size.id, size.quantity);
    this.setState({ selectedSeller: state.sellerSelected });

    this.toggleButtonSend(
      totalProductsItens + 1,
      quantity.orderMinimumQuantity,
      quantity.orderMaximumQuantity
    );
  }

  changeQuantity(event, size, collectionIndex, quantity, totalUnits, stock) {
    let new_quantity = isNaN(parseInt(event.target.value))
      ? 0
      : event.target.value;

    let bgSize = null;
    if (event.target.closest) {
      bgSize = event.target.closest(".size");
    }

    if (bgSize) {
      bgSize.classList.add("blink");
    }

    if (this.time) {
      clearTimeout(this.time);
    }

    this.time = setTimeout(() => {
      if (bgSize) {
        bgSize.classList.remove("blink");
      }
    }, 200);

    if (
      typeof stock !== "undefined" &&
      stock !== null &&
      parseInt(new_quantity) > stock.qtty_stock
    ) {
      if (bgSize) {
        let limit_alert = Array.from(bgSize.children).find((k) =>
          k.classList.contains("limit-alert")
        );
        limit_alert.classList.remove("hidden");
        setTimeout(() => {
          limit_alert.classList.add("hidden");
        }, 5000);
      }
      return;
    }

    let state = this.state;
    state.sellerSelected.collections[collectionIndex].products.forEach((product) => {
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          if (item.id === size.id) item.quantity = parseInt(new_quantity);
        })
      );
    });
    this.state.cartService.addItem(size.id, new_quantity);
    this.setState({ selectedSeller: state.sellerSelected });

    this.toggleButtonSend(
      parseInt(new_quantity),
      quantity.orderMinimumQuantity,
      quantity.orderMaximumQuantity
    );
  }

  decrementQuantity(event, size, collectionIndex, quantity, totalProductsItens, stock) {
    let bgSize = event.target.closest(".size");

    bgSize.classList.add("blink");
    if (this.time) {
      clearTimeout(this.time);
    }

    this.time = setTimeout(() => {
      bgSize.classList.remove("blink");
    }, 200);

    let parent = event.target.parentElement.parentElement;
    parent.classList.add("action-success");
    setTimeout(function () {
      parent.classList.remove("action-success");
    }, 2500);
    let state = this.state;
    state.sellerSelected.collections[collectionIndex].products.forEach((product) => {
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          if (item.quantity > 0) {
            if (item.id === size.id) {
              item.quantity -= 1;
            }
          }
        })
      );
    });

    this.state.cartService.addItem(size.id, size.quantity);
    this.setState({ selectedSeller: state.sellerSelected });
    this.toggleButtonSend(
      totalProductsItens - 1,
      quantity.orderMinimumQuantity,
      quantity.orderMaximumQuantity
    );
  }

  handleShowNotes(noteIndex) {
    let visibleNotes = this.state.visibleNotes;
    if (this.state.visibleNotes.includes(noteIndex)) {
      const index = visibleNotes.indexOf(noteIndex);
      if (index > -1) {
        visibleNotes.splice(index, 1);
        this.setState({ visibleNotes: visibleNotes });
      }
    } else {
      this.setState((prevState) => ({
        visibleNotes: [...prevState.visibleNotes, noteIndex],
      }));
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleNoteChange(event, index, collectionIndex) {
    let note = event.target.value;
    let selectedSeller = this.state.sellerSelected;

    selectedSeller.collections[collectionIndex].products[index].note = note;

    this.setState({ sellerSelected: selectedSeller });

    const timeoutKey = `${collectionIndex}-${index}`;

    if (this.timeoutMap[timeoutKey]) {
      clearTimeout(this.timeoutMap[timeoutKey]);
    }

    this.timeoutMap[timeoutKey] = setTimeout(() => {
      this.state.cartService.setNote(
        this.state.sellerSelected.cartId,
        this.state.sellerSelected.collections[collectionIndex].products[index].id,
        note
      );

      delete this.timeoutMap[timeoutKey];
    }, 1000);
  }

  showFeedback() {
    this.setState({
      showFeedback: true,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }

  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
    });
  }

  showSizeChart(product) {
    this.setState({
      sizeChartProduct: product,
      showLightBox: true,
    });
  }

  showLightBox() {
    this.setState({
      showLightBox: true,
    });
  }

  closeLightBox() {
    this.setState({
      showLightBox: false,
    });
  }

  handleAutofillClick() {
    this.setState({
      loadingAutofill: true,
    });

    const e = {
      target: {
        value: 1,
      },
    };

    for (const [collectionIndex, collection] of Object.entries(
      this.state.sellerSelected.collections
    )) {
      for (const [productIndex, product] of Object.entries(collection.products)) {
        for (const [variationIndex, variation] of Object.entries(
          product.variations
        )) {
          for (const [sizeIndex, size] of Object.entries(variation.items)) {
            let stock = variation.info.stock.find(
              (k) => k.size.id === size.size.id
            );

            this.changeQuantity(
              e,
              size,
              collectionIndex,
              product.validationsRules,
              this.totalizersCalc(productIndex, collectionIndex).totalUnits,
              stock
            );
          }
        }
      }
    }

    let buttonSend = document.getElementsByClassName("send-order-btn")[0];
    let buttonSend1 = document.getElementsByClassName("send-order-btn")[1];
    buttonSend.classList.remove("disabled");
    buttonSend1.classList.remove("disabled");

    this.setState({
      loadingAutofill: false,
    });
  }

  handleCloseUpdateAddressModal() {
    this.setState({
      showModalUpdateAddress: false,
    });
  }

  handleChangePricelist(e) {
    const newPricelistId = Number(e.target.value);
    const self = this;
    Swal.fire({
      title: "Are you sure?",
      text: `Some products may not be in the selected price list and will be removed from the cart.`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#71cfc9",
      cancelButtonColor: "#C0C0C0",
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        self.state.cartService
          .updatePricelist(self.state.sellerSelected.cartId, newPricelistId)
          .then(() => {
            this.setState({ isLoading: true });
            this.getCart(true);
          });
      }
    });
  }

  render() {
    const { t } = this.props;
    const userData = JSON.parse(localStorage.getItem("userData"));

    return (
      <React.Fragment>
        <MenuComponent />
        {!this.state.isLoading && this.state.cartEmpty && <CartEmpity />}

        {this.state.showFeedback && (
          <FeedBackMsgComponents>
            {t(
              "GREAT! NOW TRACK YOUR PRODUCTS IN",
              "GREAT! NOW TRACK YOUR PRODUCTS IN"
            )}{" "}
            <span className="here">
              <Link to="/order/">{t("ORDERS", "ORDERS")}</Link>
            </span>
            .
          </FeedBackMsgComponents>
        )}
        {this.state.showModalUpdateAddress &&
          !this.state.isLoading &&
          this.state.sellerSelected && (
            <UpdateAddressModal
              orderId={this.state.sellerSelected.orderId}
              close={this.handleCloseUpdateAddressModal.bind(this)}
            />
          )}
        {this.state.showErrorFeedback && (
          <FeedBackMsgComponents
            msg={t(
              "OOPS! SOMETHING WENT WRONG WITH YOUR REQUEST. CORRECT THE ERRORS BELOW AND TRY AGAIN.",
              "OOPS! SOMETHING WENT WRONG WITH YOUR REQUEST. CORRECT THE ERRORS BELOW AND TRY AGAIN."
            )}
            close={() => this.setState({ showErrorFeedback: false })}
          />
        )}
        <div id="cart">
          <span className="title">{t("Cart", "Cart")}</span>
          {!this.state.cartEmpty && (
            <React.Fragment>
              {!isMobile() && (
                <div className="product-brands">
                  {!this.state.isLoading && this.state.cartSidebar ? (
                    this.state.cartSidebar.map((sidebarSellerItem, i) => {
                      const item = sidebarSellerItem.find(
                        (element) => element !== undefined
                      );

                      const totalProducts = item.collections.reduce((total, collection) => {
                        return total + collection.products.length;
                      }, 0);

                      return (
                        <div key={i}>
                          {totalProducts > 0 && (
                            <div
                              onClick={() => this.sellerSelect(item.seller.id)}
                              className={`brand ${this.state.sellerSelected.seller.id ===
                                item.seller.id
                                ? "active"
                                : null
                                }`}
                            >
                              {sidebarSellerItem.length <= 1 &&
                                item.status === "o" && (
                                  <span className="cart-status cart-status-open">
                                    {t("CART OPEN", "CART OPEN")}
                                  </span>
                                )}
                              {sidebarSellerItem.length <= 1 &&
                                item.status === "c" && (
                                  <span className="cart-status cart-status-closed">
                                    {t("CART CLOSED", "CART CLOSED")}
                                  </span>
                                )}
                              <span className="brand-name">
                                {item.seller.name}
                              </span>
                              <span
                                className={`brand-item-statistic ${sidebarSellerItem.length > 1
                                  ? "brand-item-statistic--multiple-collections"
                                  : ""
                                  }`}
                              >
                                {sidebarSellerItem.length > 1 &&
                                  sidebarSellerItem.map(
                                    (sidebarSellerItemSub, index) => (
                                      <button
                                        key={index}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.cartSelect(
                                            sidebarSellerItemSub.cartId
                                          );
                                        }}
                                        className={`brand-item-statistic--button ${this.state.sellerSelected &&
                                          Number(
                                            this.state.sellerSelected.cartId
                                          ) ===
                                          Number(sidebarSellerItemSub.cartId)
                                          ? "brand-item-statistic--button--active"
                                          : ""
                                          }`}
                                      >
                                        {sidebarSellerItemSub.collection
                                          ? sidebarSellerItemSub.collection.name
                                          : t("MIXED", "MIXED")}
                                        <span className="cart-status">
                                          {sidebarSellerItemSub.status ===
                                            "o" && <br /> &&
                                            t("CART OPEN", "CART OPEN")}
                                          {sidebarSellerItemSub.status ===
                                            "c" && <br /> &&
                                            t("CART CLOSED", "CART CLOSED")}
                                        </span>
                                      </button>
                                    )
                                  )}
                                {sidebarSellerItem.length <= 1 && (
                                  <>
                                    <strong>{totalProducts}</strong>{" "}
                                    {totalProducts > 1
                                      ? t("products", "products")
                                      : t("product", "product")}{" "}
                                    {t("from", "from")}{" "}
                                    <strong>
                                      {item.seller.linesheetsCount}
                                    </strong>{" "}
                                    {item.seller.linesheetsCount > 1
                                      ? t("line sheets")
                                      : t("line sheet")}
                                  </>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SpinnerComponent />
                  )}
                  {this.state.nextPage && (
                    <div className="load-more-container">
                      <ButtonComponent
                        className="load-more"
                        action={() => this.getMoreCarts()}
                      >
                        {t("LOAD MORE")}
                        {this.state.isLoadingMore && <SpinnerComponent />}
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              )}
              <div className="product-items">
                {!this.state.isLoading && (
                  <div className="header">
                    <span className="title-container">
                      <h2>{this.state.sellerSelected.seller.name}</h2>
                    </span>
                    {
                      this.state.sellerSelected.pricelist !== null && (

                        <div className="buttons-container">
                          {this.state.sellerSelected.pricelist.id && (
                            <>
                              <ButtonComponent
                                className="send-order-btn"
                                action={() => this.exportXls()}
                              >
                                {!this.state.loadingExport ? (
                                  t("Download Editable Cart", "Download Editable Cart")
                                ) : (
                                  <SpinnerComponent />
                                )}
                              </ButtonComponent>
                              <ButtonComponent
                                className="send-order-btn"
                                action={() => this.setState({ showModalImport: true })}
                              >
                                {t("Import Edited Cart", "Import Edited Cart")}
                              </ButtonComponent>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                )}
                {!this.state.isLoading &&
                  userData.allow_auto_add_one_unit_cart &&
                  this.state.sellerSelected.status !== "c" ? (
                  <div className="autofill-one-unit">
                    <ButtonComponent action={() => this.handleAutofillClick()}>
                      {this.state.loadingAutofill ? (
                        <SpinnerComponent />
                      ) : (
                        t("AUTOFILL ONE UNIT", "AUTOFILL ONE UNIT")
                      )}
                    </ButtonComponent>
                  </div>
                ) : null}
                    {!this.state.isLoading &&
                      !this.state.isLoadingCollectionPricelists &&
                      this.state.sellerSelected.status !== "c" &&
                      this.state.collectionPricelists.length > 0 ? (
                      <div className="change-pricelist">
                        Change price list:&nbsp;
                        {
                          this.state.sellerSelected.pricelist ? (
                            <select
                              value={this.state.sellerSelected.pricelist.id}
                              onChange={this.handleChangePricelist.bind(this)}
                            >
                              {this.state.collectionPricelists.map((pricelist) => (
                                <option value={pricelist.id} key={pricelist.id}>{pricelist.name}</option>
                              ))}
                            </select>
                          ):(
                              <select
                              value={this.state.collectionPricelists[0].id}
                              onChange={this.handleChangePricelist.bind(this)}
                              >
                                {this.state.collectionPricelists.map((pricelist) => (
                                  <option value={pricelist.id} key={pricelist.id}>{pricelist.name}</option>
                                ))}
                            </select>
                          )
                        }
                      </div>
                    ) : null}

                {!this.state.isLoading && this.state.sellerSelected.status !== 'c' ?
                  this.state.sellerSelected.collections.map((singleCollection, collectionIndex) => {
                    return (
                      <div className="single-collection-box" key={collectionIndex}>
                        <h3 className="title-collection">{singleCollection.collection.name}</h3>

                        {this.state.collectionsErrors.length > 0 && (
                          <div className="feedback-collection-validation">
                            {this.state.collectionsErrors.some(
                              (error) => error.collection_id === singleCollection.collection.id
                            ) ? (
                              this.state.collectionsErrors.map((error, index) =>
                                error.collection_id === singleCollection.collection.id ? (
                                  <p className="error-collection-validation" key={index}>
                                    {error.error}
                                  </p>
                                ) : null
                              )
                            ) : this.state.errors && this.state.errors.length > 0 ? null :
                                  this.state.productsErrors && this.state.productsErrors.length > 0 ? null :
                                    this.state.errorsSizes && this.state.errorsSizes.length > 0 ? null :
                                      <p className="success-collection-validation">
                                        <img alt="" src={checkIcon} />
                                        You're all set to order!
                                      </p>
                            }
                          </div>
                        )}

                        {
                          singleCollection.products.map(
                            (product, productIndex) => {
                              const productPriceList = getCurrentPriceByPriceList(product, this.state.sellerSelected.pricelist !== null ? this.state.sellerSelected.pricelist.id : 0)
                              return (
                                <React.Fragment key={product.id}>
                                  <div
                                    className="product-details-box"
                                    id={`prod-${productIndex}`}
                                  >
                                    <div className="order-details">
                                      <div className="product-image">
                                        <img alt="" src={product.image || 'https://s3.amazonaws.com/blancfashion-api-media/product/product/product_placeholder.jpg'} />
                                        {isMobile() && (
                                          <div
                                            className="remove-cart"
                                            onClick={() =>
                                              this.removeProductFromCart(
                                                product.id,
                                                product.cartId
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faTrash} />{" "}
                                            {t("remove from cart", "remove from cart")}
                                          </div>
                                        )}
                                      </div>
                                      <div className="product-info">
                                        <div className="product-description">
                                          <Link to={`/product/${product.id}`}>
                                            <div className="product-name">
                                              {product.name}
                                            </div>
                                          </Link>
                                          <Link to={`/product/${product.id}`}>
                                            <div className="product-reference">
                                              REF: {product.referenceCode}
                                            </div>
                                          </Link>
                                          {!isMobile() && (
                                            <div
                                              className="remove-cart"
                                              onClick={() =>
                                                this.removeProductFromCart(
                                                  product.id,
                                                  product.cartId
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon icon={faTrash} />{" "}
                                              {t(
                                                "remove from cart",
                                                "remove from cart"
                                              )}
                                            </div>
                                          )}

                                          <div className="product-note">
                                            <div
                                              onClick={() =>
                                                this.handleShowNotes(productIndex)
                                              }
                                            >
                                              Add a product <b>{t("note", "note")}</b>
                                            </div>
                                            <textarea
                                              className={`${this.state.visibleNotes.includes(
                                                productIndex
                                              )
                                                ? "visible"
                                                : ""
                                                }`}
                                              name="message"
                                              value={product.note || ''}
                                              onChange={(e) =>
                                                this.handleNoteChange(e, productIndex, collectionIndex)
                                              }
                                              placeholder={
                                                "Write a message to the brand about the selected product"
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="product-prices">
                                          <div className="wholesale">
                                            <b>
                                              {t("WHOLESALE", "WHOLESALE")}{" "}
                                              {productPriceList.wholesale_price.tip || product.wholesalePrice.tip}
                                            </b>{" "}
                                            |{" "}
                                            {productPriceList.wholesale_price.currency}{" "}
                                            {parseFloat(productPriceList.wholesale_price.amount).toFixed(2)}
                                            <ReactTooltip
                                              className="variation-tooltip"
                                              id={`wholesale_price_${product.id}`}
                                              place="top"
                                              type="light"
                                              effect="solid"
                                            />
                                          </div>
                                          <div className="retail-price">
                                            <b
                                              data-tip={product.retailerPrice.tip}
                                              data-for={`retailer_price_${product.id}`}
                                            >
                                              {t("RETAIL", "RETAIL")}
                                            </b>{" "}
                                            |{" "}
                                            {productPriceList.retailer_price.currency}{" "}
                                            {parseFloat(productPriceList.retailer_price.amount).toFixed(2)}
                                            <ReactTooltip
                                              className="variation-tooltip"
                                              id={`retailer_price_${product.id}`}
                                              place="top"
                                              type="light"
                                              effect="solid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {product.variations
                                      .filter((variation) => variation.items.length > 0)
                                      .map((variation, variationIndex) => {
                                        const itemsWithError = this.orderErrorState ? Object.keys(this.orderErrorState).filter((item) => item !== "value") : []
                                        const errorItem = this.orderErrorState && this.orderErrorState[itemsWithError[0]]
                                        const itemSelected = errorItem ? variation.items.filter((item) => item.size.id === errorItem.size_id) : ""
                                        const variationStyle = GetVariationColorStyle(
                                          variation.info
                                        );
                                        const capitalizeItem = errorItem && errorItem.side
                                          ? errorItem.side.charAt(0).toUpperCase() +
                                            errorItem.side.slice(1)
                                          : "";
                                        return (
                                          <div
                                            key={variationIndex}
                                            className="product-details"
                                          >
                                            <div className="product-color">
                                              <div
                                                style={variationStyle.li}
                                                className="color-box"
                                              >
                                                <span
                                                  className="colorListNoTexture"
                                                  style={variationStyle.span}
                                                ></span>
                                              </div>
                                              <div className="color-name">
                                                {variation.info.label}
                                              </div>
                                              <div className="total-units">
                                                {t("units", "units")}:{" "}
                                                <b>
                                                  {this.countVariationTotalUnit(
                                                    variation.items
                                                  )}
                                                </b>
                                              </div>
                                            </div>
                                            <div className="product-size">
                                              {variation.items.sort((itemA, itemB) => itemA.size.order - itemB.size.order).map(
                                                (size, sizeIndex) => {
                                                  let stock = variation.info.stock.find(
                                                    (k) => k.size.id === size.size.id
                                                  );
                                                  return variation.info.stock.length >
                                                    0 &&
                                                    typeof stock === "undefined" ? (
                                                    <></>
                                                  ) : (
                                                    <div
                                                      key={size.size.id}
                                                      className="size"
                                                    >
                                                      {this.state.errorsSizes && this.state.errorsSizes.length > 0 ? (
                                                        this.state.errorsSizes.map((errorSizes) => {
                                                          if (
                                                            errorSizes.variation_id === variation.info.id &&
                                                            errorSizes.size_id === size.size.id
                                                          ) {
                                                            return (
                                                              <div
                                                                className="limit-alert"
                                                                key={`error-sizes-${variationIndex}-${size.size.id}`}
                                                              >
                                                                {errorSizes.error}
                                                              </div>
                                                            );
                                                          }
                                                          return null;
                                                        })
                                                      ) : null}
                                                      {stock && stock.qtty_stock < 5 && (
                                                        <div className="_stock_info">
                                                          {stock.qtty_stock} left
                                                          <div className="_cicle">
                                                            !
                                                          </div>
                                                        </div>
                                                      )}
                                                      <div className="bgSize" />
                                                      <div className="size-name">
                                                        {size.size.name}
                                                      </div>
                                                      <div className="size-quantity">
                                                        <div
                                                          onClick={(e) =>
                                                            this.decrementQuantity(
                                                              e,
                                                              size,
                                                              collectionIndex,
                                                              product.validationsRules,
                                                              this.totalizersCalc(
                                                                productIndex,
                                                                collectionIndex
                                                              ).totalUnits,
                                                              stock
                                                            )
                                                          }
                                                        >
                                                          -
                                                        </div>
                                                        <input
                                                          min="0"
                                                          onChange={(event) =>
                                                            this.changeQuantity(
                                                              event,
                                                              size,
                                                              collectionIndex,
                                                              product.validationsRules,
                                                              this.totalizersCalc(
                                                                productIndex,
                                                                collectionIndex,
                                                              ).totalUnits,
                                                              stock
                                                            )
                                                          }
                                                          value={size.quantity}
                                                          type="text"
                                                        />
                                                        <div
                                                          onClick={(e) =>
                                                            this.incrementQuantity(
                                                              e,
                                                              size,
                                                              collectionIndex,
                                                              product.validationsRules,
                                                              this.totalizersCalc(
                                                                productIndex,
                                                                collectionIndex
                                                              ).totalUnits,
                                                              stock
                                                            )
                                                          }
                                                        >
                                                          +
                                                        </div>
                                                      </div>
                                                      <div className="limit-alert hidden">
                                                        {stock && stock.qtty_stock}{" "}
                                                        {t(
                                                          "units are available for immediate delivery",
                                                          "units are available for immediate delivery"
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                )}
                                            </div>
                                            {this.orderErrorState &&
                                              errorItem &&
                                              variation.info.id ===
                                                errorItem.variation_id && (
                                                <ul className="list-error-order">
                                                  <li>
                                                    Size:{" "}
                                                    <p>{itemSelected[0].size.name}</p>
                                                  </li>
                                                  <li>
                                                    {capitalizeItem} quantity:{" "}
                                                    <p>{errorItem.limit}</p>
                                                  </li>
                                                </ul>
                                              )}
                                          </div>
                                        );
                                      })}

                                      {this.state.productsErrors.length > 0 && (
                                        <div className="feedback-collection-validation">
                                          {this.state.productsErrors.some(
                                            (error) => error.id === product.id
                                          ) && (
                                            this.state.productsErrors.map((error, index) =>
                                              error.id === product.id ? (
                                                <p className="error-collection-validation" key={index}>
                                                  {error.error}
                                                </p>
                                              ) : null
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>

                                  <div className="order-tools">
                                    {product.sizeChart ? (
                                      <div
                                        className="view-size-chart"
                                        onClick={() => this.showSizeChart(product)}
                                      >
                                        {t("VIEW SIZE CHART", "VIEW SIZE CHART")}
                                      </div>
                                    ) : (
                                      <div className="no-sizechart"></div>
                                    )}
                                    <div className="total-value-product">
                                      <b>
                                        {this.totalizersCalc(productIndex, collectionIndex).totalValue}
                                      </b>
                                    </div>
                                    <div className="unit-quantity">
                                      {!isMobile() && (
                                        <>
                                          {this.state.errors
                                            .filter(
                                              (item) => item.index === productIndex && item.collectionIndex === collectionIndex
                                            )
                                            .map((item, errIndex) => (
                                              <div
                                                className="box-error"
                                                key={`error-${productIndex}-${errIndex}`}
                                              >
                                                {item.error}
                                              </div>
                                            ))}
                                        </>
                                      )}
                                      {t("UNITS", "UNITS")}{" "}
                                      <b>
                                        {this.totalizersCalc(productIndex, collectionIndex).totalUnits}
                                      </b>
                                    </div>
                                  </div>
                                  {isMobile() && (
                                    <>
                                      {this.state.errors
                                        .filter((item) => item.index === productIndex)
                                        .map((item, errIndex) => (
                                          <div
                                            className="box-error"
                                            key={`error-${productIndex}-${errIndex}`}
                                          >
                                            {item.error}
                                          </div>
                                        ))}
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            }
                          )
                        }
                      </div>
                    );
                  })
                  : this.state.sellerSelected.status !== "c" ? (
                    <SpinnerComponent />
                  ) : null
                }

                {!this.state.isLoading && this.state.sellerErrors.length > 0 && (
                  <div className="feedback-collection-validation">
                    {this.state.sellerErrors.map((error, index) => {
                      return (
                        <p key={index} className="error-collection-validation">
                          {error.error}
                        </p>
                      )
                    })}
                  </div>
                )}

                {!this.state.isLoading && (
                  <React.Fragment>
                    <div className="order-resume">
                      <div className="left">
                        {this.state.sellerSelected.status === "c" && (
                          <div>
                            {t("Thank you!", "Thank you!")}
                            <br />
                            {t(
                              "Your order has been received and is currently being analysed.",
                              "Your order has been received and is currently being analysed."
                            )}
                            <br />
                            {t(
                              "You can check its progress in",
                              "You can check its progress in"
                            )}{" "}
                            <span className="highlight-order">
                              <Link
                                to={`/order/${this.state.sellerSelected.orderId}`}
                              >
                                {t("ORDERS", "ORDERS")}
                              </Link>
                            </span>
                            .
                          </div>
                        )}
                        {this.state.sellerSelected.status !== "c" && (
                          <div className="order-note">
                            <div>
                              {t("Add an order", "Add an order")}{" "}
                              <b>{t("note", "note")}</b>
                            </div>
                            <textarea
                              name="message"
                              value={this.state.message}
                              onChange={this.handleChange.bind(this)}
                              placeholder={t(
                                "Write a message to the brand about your order",
                                "Write a message to the brand about your order"
                              )}
                            />
                          </div>
                        )}
                      </div>
                      <div className="right">
                        <div>
                          <b>{t("Expected to ship", "Expected to ship")}</b>
                        </div>
                        <div>
                          {this.state.sellerSelected.collections[0].products[0].ship_msg}
                        </div>
                        <div className="order-note">
                          <div className="total">
                            {this.state.valueError && (
                              <div className="box-error">
                                {this.state.valueError}
                              </div>
                            )}
                            <b className="uppercase">
                              {t("TOTAL", "TOTAL")}{" "}
                              {this.state.totalProducts > 0
                                ? getCurrentPriceByPriceList(
                                  this.state.sellerSelected.collections[0].products[0],
                                  this.state.sellerSelected.pricelist !== null ? this.state.sellerSelected.pricelist.id : 0
                                ).wholesale_price.tip
                                : ""}
                            </b>{" "}
                            |{" "}
                            {this.generalTotalizersCalc().allValues.replace(
                              "US$",
                              "USD"
                            )}
                          </div>
                          <div className="quantity">
                            {this.state.quantityError && (
                              <div className="box-error">
                                {this.state.quantityError}
                              </div>
                            )}
                            <b>{t("UNITS", "UNITS")}</b> |{" "}
                            <span>
                              {this.generalTotalizersCalc().totalAllUnits}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.sellerSelected.status !== "c" && (
                      <div className="send-box">
                        <ButtonComponent
                          action={() => this.finalizeOrderCart()}
                          disabled={this.state.isLoading}
                          className={`send-order-btn ${this.state.sellerSelected.seller.id ===
                            this.state.lastAddedId
                            ? "add-success"
                            : ""
                            }`}
                        >
                          {!this.state.sendOrderLoading ? (
                            t("SEND ORDER", "SEND ORDER")
                          ) : (
                            <SpinnerComponent />
                          )}
                        </ButtonComponent>
                        <span onClick={() => this.removeAllProducts()}>
                          {t(
                            "remove all brand's products from cart",
                            "remove all brand's products from cart"
                          )}
                        </span>
                      </div>
                    )}
                    {this.state.sellerSelected.status === "c" && (
                      <div className="send-box">
                        {this.state.sellerSelected.orderCheckoutUrl && (
                          <a href={`${this.state.sellerSelected.orderCheckoutUrl}`}
                            target="_blank"
                          >
                            <button>{t("VIEW INVOICE", "VIEW INVOICE")}</button>
                          </a>
                        )}
                        <Link
                          to={`/order/${this.state.sellerSelected.orderId}`}
                        >
                          <button>{t("VIEW ORDER", "VIEW ORDER")}</button>
                        </Link>
                      </div>
                    )}
                    {isMobile() && (
                      <>
                        {this.state.sellerSelected.status !== "c" ? (
                          <div
                            onClick={() => this.setState({ cartList: true })}
                            className="see-all"
                          >
                            {t("See All Carts", "See All Carts")}
                          </div>
                        ) : (
                          <Link to="/order/">
                            <div className="see-all">
                              {t("See All Orders", "See All Orders")}
                            </div>
                          </Link>
                        )}
                        <div
                          className={`cart-list-mobile ${this.state.cartList ? "cart-list-opened" : ""
                            }`}
                        >
                          <div
                            onClick={() => this.setState({ cartList: false })}
                            className="btn-close-cartlist"
                          >
                            X
                          </div>
                          <div className="product-brands">
                            {!this.state.isLoading ? (
                              this.state.cartItems.map((item, i) => {
                                return (
                                  <div key={i}>
                                    {item.products.length > 0 && (
                                      <div
                                        onClick={() =>
                                          this.sellerSelect(item.seller.id)
                                        }
                                        className={`brand ${this.state.sellerSelected.seller
                                          .id === item.seller.id
                                          ? "active"
                                          : null
                                          }`}
                                      >
                                        {item.status === "o" && (
                                          <span className="cart-status cart-status-open">
                                            {t("CART OPEN", "CART OPEN")}
                                          </span>
                                        )}
                                        {item.status === "c" && (
                                          <span className="cart-status cart-status-closed">
                                            {t("CART CLOSED", "CART CLOSED")}
                                          </span>
                                        )}
                                        <span className="brand-name">
                                          {item.seller.name}
                                        </span>
                                        <span className="brand-item-statistic">
                                          <strong>
                                            {item.products.length}
                                          </strong>{" "}
                                          {item.products.length > 1
                                            ? t("products", "products")
                                            : t("product", "product")}{" "}
                                          {t("from", "from")}{" "}
                                          <strong>
                                            {item.seller.linesheetsCount}
                                          </strong>{" "}
                                          {item.seller.linesheetsCount > 1
                                            ? t("line sheets", "line sheets")
                                            : t("line sheet", "line sheet")}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <SpinnerComponent />
                            )}
                            {this.state.nextPage && (
                              <div className="load-more-container">
                                <ButtonComponent
                                  className="load-more"
                                  action={() => this.getMoreCarts()}
                                >
                                  {t("LOAD MORE", "LOAD MORE")}
                                  {this.state.isLoadingMore && (
                                    <SpinnerComponent />
                                  )}
                                </ButtonComponent>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>

        {this.state.showModalImport && (
          <LightboxHalfScreenComponent
            close={() => this.setState({ showModalImport: false })}
            extraClass="modal-import"
          >
            <h1><strong>IMPORT EDITED LIST OF SELECTED PRODUCTS</strong></h1>
            <div className="modal-content">
              <h2>SELECT FILE FOR IMPORT IN CART</h2>
              <h3>{this.state.sellerSelected.seller.name}</h3>
              <br />
              <form onSubmit={this.sendImport.bind(this)}>

                <div className="file-upload-wrapper" data-text="Select your file!">
                  <input required type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.handleImportInput.bind(this)} />
                </div>

                <button disabled={this.state.loadingImport} type="submit" className="btn-import">
                  {this.state.loadingImport ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : "IMPORT"}
                </button>
              </form>
            </div>
          </LightboxHalfScreenComponent>
        )}
        {this.state.showModalReplaceCart && (
          <LightboxHalfScreenComponent
            close={() => this.setState({ showModalReplaceCart: false })}
            extraClass="modal-replace"
          >
            <h2>You've already initiated a cart for</h2>
            <div className="modal-content">
              <h1><strong>{this.state.sellerSelected.seller.name}</strong></h1>
              <br />
              <div className="replace-buttons">
                <button disabled={this.state.loadingReplace || this.state.loadingAddToCart} onClick={this.sendReplace.bind(this)} className="btn-replace">
                  {this.state.loadingReplace ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : "REPLACE IT"}
                </button>
                <button disabled={this.state.loadingReplace || this.state.loadingAddToCart} onClick={this.sendReplace.bind(this, false)} className="btn-add">
                  {this.state.loadingAddToCart ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : "ADD PRODUCTS TO THE EXISTING CART"}
                </button>
              </div>

            </div>
          </LightboxHalfScreenComponent>
        )}
        {this.state.showModalGoToCart && (
          <LightboxHalfScreenComponent
            // close={() => this.setState({ showModalGoToCart: false })}
            extraClass="modal-go-to-cart"
          >
            <h1><strong>Your cart was successfully {this.state.operationWord}</strong></h1>
            <div className="modal-content">
              <a href={`/cart/${this.state.cartId}`}>
                <button className="btn-go-to-cart">
                  Go to CART to place the order
                </button>
              </a>
            </div>
          </LightboxHalfScreenComponent>
        )}

        {this.state.showLightBox && (
          <ProductSizeChart
            productId={this.state.sizeChartProduct.id}
            sizeChart={this.state.sizeChartProduct.sizeChart}
            show={this.state.showLightBox}
            handleClick={this.closeLightBox.bind(this)}
            productName={this.state.sizeChartProduct.name}
            productReferenceCode={this.state.sizeChartProduct.reference_code}
          />
        )}
        <FooterComponent />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartData: state.cartEntity,
  };
}

export default withTranslation()(connect(mapStateToProps)(CartViewComponent));
