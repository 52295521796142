import React from 'react';
import SelectCollectionBox from "./SelectCollectionBox";


const SelectCollectionComponent = ({ seller, name, id, image }) => (
  <div>
      <SelectCollectionBox
          seller={seller}
          name={name}
          id={id}
          image={image}
      />
  </div>
);


export default SelectCollectionComponent;
