import React, { Component } from "react";
import "../styles/FeedBackMsgComponents.scss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class FeedBackMsgComponents extends Component {
  render() {
    const { t } = this.props;
    let link;
    if (this.props.link) {
      link = (
        <Link to={this.props.link} className="button">
          {t("GOT IT", "GOT IT")}
        </Link>
      );
    } else if (this.props.close) {
      link = (
        <button onClick={this.props.close} className="button">
          {t("GOT IT", "GOT IT")}
        </button>
      );
    } else {
      link = (
        <Link to="/cart" className="button">
          {t("GOT IT", "GOT IT")}
        </Link>
      );
    }
    return (
      <div>
        <div className="containerFeedBack">
          <div className="text">
            {this.props.children && this.props.children}
            <div dangerouslySetInnerHTML={{ __html: this.props.msg }} />
          </div>
          {link}
        </div>
      </div>
    );
  }
}

export default withTranslation()(FeedBackMsgComponents);
