import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import "../style/FiltersStyle.scss"

class FiltersComponent extends Component {

    makeActive(ev, id) {
        this.props.makeFilter(id);
        if(ev.target.classList.contains("active")){
            ev.target.classList.remove("active");
        } else {
            if(document.querySelectorAll('.listFilters .active').length > 0) {
                document.querySelectorAll(".listFilters .active").forEach( (el) => {
                    console.log(el.classList.remove("active"))
                });
            }
            ev.target.classList.add("active");
        }
    }

    render() {

        const {filters, link, seller, t} = this.props;

        return (
            <div className="filters">
                <p className="titleFilters">
                    {t('Filters','Filters')}
                </p>

                <div className="listFilters">
                    <aside className={filters.length > 6 ? "scroll" : ""}>
                        {filters.map((filter, index) => {
                          if(link){
                            return (
                              <Link key={index} to={
                                window.localStorage.getItem("userData") ?
                                    `/products/?sellers=${seller}&categories=${filter.id}` :
                                        `/${seller}/register`
                                        }>
                              <div >
                                  {filter.name}
                              </div>
                              </Link>
                            )
                          }else{
                            return (
                              <div key={index} onClick={(ev) => this.makeActive(ev, filter.id)}>
                              {filter.name}
                              </div>
                            )
                          }
                        })}
                    </aside>
                </div>

            </div>
        );
    }
}

export default withTranslation()(FiltersComponent);
