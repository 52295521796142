import React, { Component } from "react";
import "./style/MenuBrandsStyle.scss";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class MenuBrandsComponent extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="menuBrands">
        <ul>
          <li className="brand">
            <NavLink
              to={`/${this.props.brandSlug}`}
              exact={true}
              activeClassName="active"
            >
              {t("ABOUT THE BRAND", "ABOUT THE BRAND")}
            </NavLink>
          </li>
          <li className="collection">
            {
              window.localStorage.getItem("userData") ? (
                <>
                  <NavLink
                    to={`/${this.props.brandSlug}/collections`}
                    activeClassName="active"
                  >
                    {(!window.localStorage.getItem("userData") || window.localStorage.getItem("user_type") === "buyer") ? (
                      <>{t("MAKE A BUY", "MAKE A BUY")}</>
                    ) : (
                      <>{t("COLLECTIONS", "COLLECTIONS")}</>
                    )}
                  </NavLink>
                </>
              ):(
                <>
                  <NavLink
                      to={`/${this.props.brandSlug}/register`}
                      activeClassName="active"
                    >
                        <>{t("MAKE A BUY", "MAKE A BUY")}</>
                  </NavLink>
                </>
              )
            }
          </li>
          <li className="history">
            <NavLink
              to={`/${this.props.brandSlug}/history`}
              activeClassName="active"
            >
              {t("BRAND HISTORY", "BRAND HISTORY")}
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default withTranslation()(MenuBrandsComponent);
