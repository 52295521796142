import React, { Component } from "react";

import { withRouter } from "react-router";
import SortByFilter from "../../../shared/sortbyfilter/components/SortByFilter";
import FilterCollectionsComponent from "../../../filtercollections/components/FilterCollectionsComponent";
import MenuCollectionComponent from "../../../menucollection/components/MenuCollectionComponent";
import ProductComponent from "../../../shared/products/components/ProductComponent";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import "../style/CollectionStyle.scss";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import mixpanel from "mixpanel-browser";
import { isMobile } from "is-mobile";
import { withTranslation } from "react-i18next";

class CollectionComponent extends Component {
  constructor(props) {
    super(props);

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const page = params.get("page");

    let pageNumber = page ? parseInt(page) : 1;

    this.state = {
      collections: [],
      loading: true,
      filterOpened: false,
      stateParam: "",
      loadingCollection: true,
      collectionId: "",
      collection: "",
      activePage: pageNumber,
      lookBookUrl: "",
      pressRelease: "",
      modalSelectPricelist: false,
      pricelistCartCheck: false,
      pricelitsValue: "",
      pricelist: this.getPricelistFromUrl(),
      products: []
    };
    this.setSelectPricelistModal = this.setSelectPricelistModal.bind(this);
    this.setPricelistCartCheck = this.setPricelistCartCheck.bind(this);
  }

  setPricelistCartCheck(){
    this.setState({
      pricelistCartCheck: true
    })
  }


  setSelectPricelistModal(){
    this.setState({
      modalSelectPricelist: true
    })
  }

  getPricelistFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('pricelist');
  }

  componentDidMount() {
    if (this.props.results.meta.collections[0] && this.props.brandData) {
      if (
        process.env.REACT_APP_MIXPANEL_ENABLED === true ||
        process.env.REACT_APP_MIXPANEL_ENABLED === "true"
      ) {
        if(window.localStorage.getItem("userData")) {
        mixpanel.identify(
          `${JSON.parse(window.localStorage.getItem("userData")).id}`
        );
      }
        mixpanel.track("Collection Page", {
          seller: this.props.brandData.name,
          seller_id: this.props.brandData.id,
          collection: this.props.results.meta.collections[0].name,
          collection_id: this.props.results.meta.collections[0].id,
        });
      }
    }
    
    if (this.state.activePage !== 1) {
      this.handlePageChange(this.state.activePage);
    }
    const params = new URLSearchParams(window.location.search);

    if(["null", 0, "0", ""].includes(window.localStorage.getItem("user.pricelist")) || ["null", null, "", 0, "0"].includes(params.get('pricelist'))){
      this.setState({
        modalSelectPricelist: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    
    const prevPricelist = new URLSearchParams(prevProps.location.search).get('pricelist');
    const currentPricelistvalue = new URLSearchParams(this.props.location.search).get('pricelist');
    
    // Atualiza valor do pricelist no modal se não houver pricelist
    if ([''].includes(prevPricelist) && ![null].includes(currentPricelistvalue) && prevPricelist !== currentPricelistvalue) {
      if(currentPricelistvalue !== '0'){
        this.setState({ 
          pricelitsValue: [null, '', undefined, 'null'].includes(currentPricelistvalue) ? '' : currentPricelistvalue,
          modalSelectPricelist: false, 
        });
        this.props.setFilters("pricelist", [null, '', undefined, 'null'].includes(currentPricelistvalue) ? '' : currentPricelistvalue)
      }
    }

    // Atualiza toda vez que o pricelist da URL altera
    const currentPricelist = this.getPricelistFromUrl();
    if (![null, undefined, 'null'].includes(prevState.pricelist) && currentPricelist !== prevState.pricelist) {
      if(currentPricelist === ''){
        this.setState({
          modalSelectPricelist: true
        })
      }
      this.setState({ 
        pricelist: currentPricelist,
        activePage: 1 
      });
    }
  }

  handlePageChange(pageNumber) {
    if (isNaN(pageNumber)) {
      pageNumber = 1;
    }
    this.setState({ activePage: parseInt(pageNumber) });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const pricelist = params.get("pricelist");
    
    this.props.history.replace({
      pathname: this.state.pathname,
      search: `?page=${pageNumber}&pricelist=${pricelist || ''}`,
    });
    this.props.setFilters("page", pageNumber);
  }

  setCurrentPageOne() {
    let search = window.localStorage.getItem("user.pricelist") ? `page=1&pricelist=${window.localStorage.getItem("user.pricelist")}` : '?page=1' 

    this.setState({ activePage: 1 });
    this.props.history.push({
      pathname: this.state.pathname,
      search: search,
    });
  }

  setCollection(id, lookbookurl, pressRelease) {
    this.setState({
      collectionId: id,
      lookBookUrl: lookbookurl,
      pressRelease: pressRelease,
    });
    this.setCurrentPageOne();
  }

  render() {
    const { t } = this.props;
    const { meta } = this.props.results;

    if (this.props.loadingProduct) {
      return <LoadingComponent />;
    }
    
    return (
      <div>
        {/* Modal select pricelist */}
        {this.state.modalSelectPricelist && 
        <div className="backgroundSelectPricelist">
          <div className="modalSelectPricelist">
            <div class="modalSelectPricelist-header">
              <span>
                Select price list
              </span>
              <button onClick={() => this.setState({modalSelectPricelist: false})}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33366 11.8333L0.166992 10.6667L4.83366 5.99999L0.166992 1.33332L1.33366 0.166656L6.00033 4.83332L10.667 0.166656L11.8337 1.33332L7.16699 5.99999L11.8337 10.6667L10.667 11.8333L6.00033 7.16666L1.33366 11.8333Z" fill="#272626"/>
                </svg>
              </button>
            </div>
            <div class="modalSelectPricelist-body">
              {this.state.pricelistCartCheck && <p>Please select one price list before adding products to your cart.</p>}
              <select
                value={this.state.pricelitsValue}
                onChange={(e) => {
                  if (e.target.value !== "0") {
                    this.setState({
                      pricelitsValue: e.target.value,
                    });
                  }
                }}
              >
                <option value="">Select price list</option>
                {meta &&
                  meta.collections[0] &&
                  meta.collections[0].pricelists.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              <div>
                <button 
                className={`${["", "0", "null"].includes(this.state.pricelitsValue) && "disabled"}`}
                disabled={["", "0", "null"].includes(this.state.pricelitsValue)}
                onClick={() => {
                  const params = new URLSearchParams(this.props.location.search);
                  params.set('pricelist', this.state.pricelitsValue);
                  this.props.history.replace({ search: params.toString() });
                  localStorage.setItem('user.pricelist', this.state.pricelitsValue);
                }}>
                  SET PRICE LIST
                </button>
              </div>
            </div>
          </div>
        </div>}
        <main>
          {!isMobile() && (
            <section id="sotBy" className="centerComponent">
              <SortByFilter setFilters={this.props.setFilters.bind(this)} />
            </section>
          )}

          {isMobile() && (
            <div className="info">
              <section id="sotBy" className="centerComponent">
                <SortByFilter setFilters={this.props.setFilters.bind(this)} />
              </section>
              {meta.selected_segment.name && !meta.selected_collection.seller && (
                <div className="nameAndBrands">
                  {t("Products", "Products")} | {meta.selected_segment.name}
                </div>
              )}
              {meta.selected_seller.name &&
                !meta.selected_collection.seller && (
                  <div className="nameAndBrands">
                    {meta.selected_seller.name}
                  </div>
                )}
              {meta.selected_collection.seller && (
                <>
                  <div className="nameAndBrands">
                    {`${meta.selected_collection.seller.name}
                                    | ${meta.selected_collection.name}`}
                  </div>
                  <div className="order styleBorder">
                    {meta.selected_collection.order_window_end && (
                      <div>
                        <span className="emph">
                          {t(
                            "Order window ending on",
                            "Order window ending on"
                          )}
                          :
                        </span>{" "}
                        {meta.selected_collection.order_window_end}
                      </div>
                    )}
                    {meta.selected_collection.production_window_start &&
                      meta.selected_collection.production_window_end && (
                        <div>
                          <span className="emph">
                            {t("Expected to ship:", "Expected to ship")}
                          </span>{" "}
                          {meta.selected_collection.production_window_start} to{" "}
                          {meta.selected_collection.production_window_end}
                        </div>
                      )}
                    <div>{meta.selected_collection.delivery_msg}</div>
                  </div>
                </>
              )}
            </div>
          )}

          <section id="mainCollection" className="centerComponent">
            <button
              onClick={() =>
                this.setState({ filterOpened: !this.state.filterOpened })
              }
              className={`btn-show-filter ${this.state.filterOpened ? "clicked" : ""
                }`}
            >
              Filters
            </button>
            <div
              className={`leftCollection ${this.state.filterOpened ? "opened" : ""
                }`}
            >
              <FilterCollectionsComponent
                meta={this.props.results.meta}
                collections={this.props.results.meta.collections}
                isCollection={this.props.isCollection}
                isBrand={this.props.isBrand}
                isBrandPage={this.props.isBrandPage}
                isSegment={this.props.isSegment}
                setFilters={this.props.setFilters.bind(this)}
                getFilters={this.props.getFilters.bind(this)}
                getProducts={this.props.getProducts.bind(this)}
                handleClick={this.props.handleClick.bind(this)}
                clearAllFilter={this.props.clearAllFilter.bind(this)}
                setCurrentPageOne={this.setCurrentPageOne.bind(this)}
                endPoint={this.props.endPoint}
                setCollection={this.setCollection.bind(this)}
                collectionId={this.state.collectionId}
              >
                {this.props.results.meta.selected_collection &&
                  "lookbook_url" in
                  this.props.results.meta.selected_collection && (
                    <MenuCollectionComponent
                      collections={this.props.results.meta}
                      collectionId={this.state.collectionId}
                      lookBookUrl={this.state.lookBookUrl}
                      pressRelease={this.state.pressRelease}
                      fullCollection={this.props.results.meta.selected_collection}
                      productsTotal={this.props.results.pagination.count}
                      getFilters={this.props.getFilters.bind(this)}
                      selectedSegment={
                        Object.keys(this.props.results.meta.selected_segment)
                          .length > 0
                          ? this.props.results.meta.segments.find(
                            (item) =>
                              item.name ===
                              this.props.results.meta.selected_segment.name
                          ).id || null
                          : null
                      }
                    />
                  )}
              </FilterCollectionsComponent>
            </div>

            <div className="rightCollection">
              <ProductComponent
                loading={this.props.loading}
                pagination={this.props.results.pagination}
                products={this.props.results.results}
                setFilters={this.props.setFilters.bind(this)}
                currentPage={this.props.currentPage}
                isCheckBox={false}
                modalPricelist={this.setSelectPricelistModal}
                pricelistCartCheck={this.setPricelistCartCheck}
              />

              <p className="results">
                {this.props.results.pagination.count}{" "}
                {this.props.results.pagination.count > 1
                  ? t("results", "results")
                  : t("result", "result")}
              </p>

              {this.props.results.pagination.num_pages > 1 && (
                <div className="contentPagination">
                  <Pagination
                    hideDisabled
                    activePage={this.state.activePage}
                    itemsCountPerPage="12"
                    totalItemsCount={this.props.results.pagination.count}
                    onChange={this.handlePageChange.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                  />
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    );
  }
}

CollectionComponent.propType = {
  collection: PropTypes.array.isRequired,
  getFilters: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(CollectionComponent));
