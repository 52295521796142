import React, { Component } from "react";
import "../style/FilterCollectionsStyle.scss";
import { withRouter } from "react-router";

import NavCategoryComponent from "../../shared/navcategory/components/NavCategoryComponent";
import NavSegmentComponent from "../../shared/navsegment/components/NavSegmentComponent";
import NavBrandsComponent from "../../shared/navBrands/components/NavBrandsComponent";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isMobile } from "is-mobile";
import { withTranslation } from "react-i18next";

class FilterCollectionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricelitsValue: "",
    };
  }

  clearFilter(endpoint) {
    this.props.clearAllFilter();
    this.props.getProducts(endpoint);
    
    // Pricelist
    this.setState({
      pricelitsValue: "",
    });
    const url = new URL(window.location.href);
    url.searchParams.set('pricelist', '');
    window.location.search = url.search;
  }

  changeSegment(endpoint) {
    this.props.clearAllFilter();
    this.props.setCurrentPageOne();
    this.props.getProducts(endpoint);
  }

  componentDidMount() {
    const { meta } = this.props;
    const url = new URL(window.location.href);
    const urlSearchParams = new URLSearchParams(url.search);
    const pricelistParameters = urlSearchParams.get("pricelist");

    if (
      pricelistParameters &&
      pricelistParameters !== "0" &&
      meta.collections[0] &&
      meta.collections[0].pricelists
    ) {
      const pricelistId = meta.collections[0].pricelists.find(
        (item) => item.id === +pricelistParameters
      );
      this.setState({
        pricelitsValue: pricelistId.id || '',
      });
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.location.search !== this.props.location.search) {
      const params = new URLSearchParams(window.location.search);
      const pricelistParams = params.get('pricelist');
      this.setState({ pricelitsValue: pricelistParams || '' });
    }
  }

  handleSelectPricelist(value) {
    if (value !== "") {
      this.props.clearAllFilter();
      this.props.setCurrentPageOne();
      this.setState({
        pricelitsValue: value,
      });
      const params = new URLSearchParams(window.location.search);
      params.set('pricelist', value);
      
      if(params.has('page')){
        params.delete('page')
      }
      
      window.localStorage.setItem("user.pricelist", value)
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
      this.props.setFilters("pricelist", value)
    }
  }

  render() {
    const { meta, isBrand, collections, t } = this.props;
    const filters = this.props.getFilters();
    
    return (
      <div className="filterCollection">
        {!isMobile() && (
          <>
            {meta.selected_segment.name && !meta.selected_collection.seller && (
              <div className="nameAndBrands">
                {t("Products", "Products")}| {meta.selected_segment.name}
              </div>
            )}
            {meta.selected_seller.name && !meta.selected_collection.seller && (
              <div className="nameAndBrands">{meta.selected_seller.name}</div>
            )}
            {meta.selected_collection.seller && (
              <>
                <div className="nameAndBrands">
                  {`${meta.selected_collection.seller.name}
                      | ${meta.selected_collection.name}`}
                </div>
                <div className="order styleBorder">
                  {meta.selected_collection.order_window_end && (
                    <div>
                      <span className="emph">
                        {t("Order window ending on", "Order window ending on")}:
                      </span>{" "}
                      {meta.selected_collection.order_window_end}
                    </div>
                  )}
                  {meta.selected_collection.production_window_start &&
                    meta.selected_collection.production_window_end && (
                      <div>
                        <span className="emph">
                          {t("Expected to ship", "Expected to ship")}:
                        </span>{" "}
                        {meta.selected_collection.production_window_start}{" "}
                        {t("to", "to")}{" "}
                        {meta.selected_collection.production_window_end}
                      </div>
                    )}
                  <div>{meta.selected_collection.delivery_msg}</div>
                </div>
              </>
            )}
          </>
        )}
        {
          <div className="selectPricelist-brand">
            <span>SELECT PRICE LIST</span>
            <select
              value={this.state.pricelitsValue}
              onChange={(e) => {
                this.handleSelectPricelist(e.target.value);
              }}
            >
              <option value="">Select price list</option>
              {meta &&
                meta.collections[0] &&
                meta.collections[0].pricelists.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        }

        {this.props.children}

        {this.props.isSegment && (
          <NavSegmentComponent
            changeSegment={this.changeSegment.bind(this)}
            segments={meta.segments}
            setCurrentPageOne={this.props.setCurrentPageOne.bind(this)}
            endPoint={this.props.endPoint}
          />
        )}

        <NavCategoryComponent
          categories={meta.categories}
          setFilters={this.props.setFilters}
          getFilters={this.props.getFilters}
          setCurrentPageOne={this.props.setCurrentPageOne.bind(this)}
        />

        {isBrand && (
          <NavBrandsComponent
            brands={meta.sellers}
            setFilters={this.props.setFilters}
            getFilters={this.props.getFilters}
            setCurrentPageOne={this.props.setCurrentPageOne.bind(this)}
          />
        )}
        {meta.selected_seller.slug &&
          filters.sellers &&
          collections.length > 0 && (
            <div className="contentFilter">
              <p>{t("Line sheets", "Line sheets")}</p>
              <ul>
                {collections.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={(ev) =>
                        this.clearFilter(
                          `?collections=${item.id}&sellers=${item.seller.slug}`
                        )
                      }
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        <div className="clearAllFilter" onClick={() => this.clearFilter()}>
          {t("Clear all filters", "Clear all filters")}
        </div>
        {meta.selected_seller.slug && filters.sellers && (
          <div id="brandPage">
            <Link to={`/${meta.selected_seller.slug}`}>
              {t("BRAND PAGE", "BRAND PAGE")}
            </Link>
          </div>
        )}
      </div>
    );
  }
}
FilterCollectionsComponent.propTypes = {
  getFilters: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(FilterCollectionsComponent));
