import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OrderService from '../../../data/pages/order/OrderService';
import LightBoxComponent from '../../shared/lightbox/components/LightBoxComponent';
import LoginComponent from '../../shared/Login/components/LoginComponent';
import isMobile from 'is-mobile';
import Menu from '../../shared/header/components/Menu';

import '../style/MenuStyle.scss';
import AuthenticationService from '../../../services/AuthenticationService';
import { withTranslation } from 'react-i18next';
import OrderCartAuthentication from '../../ordercartauthentication/components/OrderCartAuthentication';
import ButtonComponent from '../../shared/button/components/ButtonComponent';

const RenderLightBox = (props) => {
    const { show, handleClick } = props;

    return (
        <LightBoxComponent isOpen={show} handleClick={handleClick} handleEventClose={handleClick}>
            <LoginComponent />
        </LightBoxComponent>
    );
};

class MenuComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLightBox: false,
            loading: true,
            offsetDropdown: 60,
            segmentsOpened: false,
            projectsOpened: false,
            quantityUnreadMessages: 0,
        };
    }

    showLightBox() {
        this.setState({
            showLightBox: true,
        });
    }

    closeLightBox() {
        this.setState({
            showLightBox: false,
        });
    }

    handleClickProjects() {
        this.setState({ projectsOpened: !this.state.projectsOpened });
    }

    async componentDidMount() {
        let orderService = new OrderService();
        if (window.localStorage.getItem('access_token')) {
            let quantityUnreadMessages = (await orderService.getMessages()) || 0;

            this.setState({
                loading: false,
                quantityUnreadMessages: quantityUnreadMessages,
            });
        } else {
            this.setState({
                loading: false,
            });
        }

    }

    active(ev) {
        if (document.getElementsByClassName('active')[0] !== undefined) {
            document.getElementsByClassName('active')[0].classList.remove('active');
        }
        ev.target.closest('li').classList.add('active');
    }

    handleClick(endPoint, ev) {
        this.active(ev);
        this.props.handleClick();
        this.props.getProducts(endPoint);
    }

    closeMenuMobile() {
        let menu = document.getElementById('navItems');
        menu.style.display = 'none';
    }

    static isNotLogged() {
        return window.localStorage.getItem('user') === null;
    }

    getMarginDropDownMenu() {
        return `-${this.state.offsetDropdown / 2}.px`;
    }

    getWidthDropDownMenu(menu) {
        const menuEl = document.getElementById(`dropdown-wrapper-${menu}`);

        if (menuEl) {
            return `${menuEl.offsetWidth + this.state.offsetDropdown}.px`;
        }
    }

    openMenuMobile() {
        let menu = document.getElementById('navItems');
        menu.style.display = 'block';
    }

    render() {
        const { t } = this.props;
        const xAccountLogo = localStorage.getItem('xAccountLogo');

        return (
            <div className='menu-component'>
                <div className='MenuResponsive' id='MenuResponsive' onClick={() => this.openMenuMobile()}>
                    <span />
                    <span />
                    <span />
                </div>
                {isMobile() && (
                    <div className='logo-wrapper-mobile'>
                        <img src={xAccountLogo} alt='' />
                    </div>
                )}
                <nav id='navItems'>
                    <div id='closeMenu' onClick={() => this.closeMenuMobile()}>
                        x
                    </div>
                    <div
                        className={`centerNav ${this.state.segmentsOpened ? 'segments-open' : ''} ${this.state.projectsOpened ? 'projects-open' : ''
                            }`}>
                        {/* {isMobile() && <SearchComponent />} */}
                        <div className='left'>
                            <div className='logo-wrapper'>
                                <a href='/'>
                                    <img src={xAccountLogo} alt='' />
                                </a>
                            </div>
                            {this.state.loading ? (
                                <ul>
                                    <li>
                                        {
                                            window.localStorage.getItem('user_stype') ? (
                                                <>
                                                    {
                                                        window.localStorage.getItem('user_stype') !== 'buyer' ? (
                                                            <Link onClick={() => {
                                                                AuthenticationService.getInfoUser().then((data) => {window.location.href = `/${data.seller_slug}`})
                                                            }}>
                                                                {t('My Brand', 'My Brand')}
                                                            </Link>
                                                        ) : (
                                                            <Link to='/brands'>
                                                                {t('Our Brands', 'Our Brands')}
                                                            </Link>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <>{t('Brands', 'Brands')}</>
                                            )
                                        }
                                    </li>
                                </ul>
                            ) : localStorage.getItem('access_token') ? (
                                <>
                                    {
                                        window.localStorage.getItem('user_stype') !== 'buyer' && (
                                            <ul>
                                                <li>
                                                    <Link to={`/${window.localStorage.getItem('seller_slug')}`}>
                                                        {t('My Brand', 'My Brand')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/buyers'>{this.props.t('Find retailers', 'Find retailers')}</Link>
                                                </li>
                                            </ul>
                                        )
                                    }
                                </>
                            ) : (
                                <ul>
                                    <li>
                                        {
                                            window.localStorage.getItem('user_stype') ? (
                                                <>
                                                    {
                                                        window.localStorage.getItem('user_stype') !== 'buyer' ? (
                                                            <Link onClick={() => {
                                                                AuthenticationService.getInfoUser().then((data) => {window.location.href = `/${data.seller_slug}`})
                                                            }}>
                                                                {t('My Brand', 'My Brand')}
                                                            </Link>
                                                        ) : (
                                                            <Link to='/brands'>
                                                                {t('Our Brands', 'Our Brands')}
                                                            </Link>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <Link to='/brands'>
                                                    {t('Brands', 'Brands')}
                                                </Link>
                                            )
                                        }
                                    </li>
                                </ul>
                            )}
                        </div>
                        {MenuComponent.isNotLogged() ? (
                            <div id='LoginMobile'>
                                <p onClick={() => this.showLightBox()}>{t('sign in', 'sign in')}</p>
                                {this.state.showLightBox && (
                                    <RenderLightBox
                                        show={this.state.showLightBox}
                                        handleClick={() => this.closeLightBox()}
                                    />
                                )}
                            </div>
                        ) : (
                            <div id='LoginMobile'>
                                {window.localStorage.getItem('user_stype') !== 'buyer' ? (
                                    <ul>
                                        <li>
                                            <Link to={`/${window.localStorage.getItem('seller_slug')}`}>
                                                {t('My Brand', 'My Brand')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/buyers'>{this.props.t('Find retailers', 'Find retailers')}</Link>
                                        </li>
                                        <li>
                                            <Link to='/cart'>{t('Cart', 'Cart')}</Link>
                                        </li>
                                        {window.localStorage.getItem('user_stype') ? (
                                            <li>
                                                <Link to='/order'>{t('Orders', 'Orders')}</Link>
                                            </li>
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>
                                            <Link to='/brands'>{t('Our Brands', 'Our Brands')}</Link>
                                        </li>
                                        <li>
                                            <Link to='/cart'>{t('Cart', 'Cart')}</Link>
                                        </li>
                                        {window.localStorage.getItem('user_stype') ? (
                                            <li>
                                                <Link to='/order'>{t('Orders', 'Orders')}</Link>
                                            </li>
                                        ) : (
                                            <></>
                                        )}

                                        {window.localStorage.getItem('user_stype') === 'buyer' && (
                                            <li>
                                                <Link to='/profile'>{t('My Profile', 'My Profile')}</Link>
                                            </li>
                                        )}
                                    </ul>
                                )}
                                <ButtonComponent action={() => AuthenticationService.logout()}>
                                    {t('Logout', 'Logout')}
                                </ButtonComponent>
                            </div>
                        )}
                        <div className='right'>
                            {!isMobile() && (
                                <>
                                    {window.localStorage.getItem('user_stype') ? (
                                        <div className='menu-orders'>
                                            <Link to='/order'>
                                                {t('ORDERS', 'ORDERS')}
                                                {this.state.quantityUnreadMessages > 0 ? (
                                                    <span class='notification-badge'></span>
                                                ) : null}
                                            </Link>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            {!isMobile() && (
                                <OrderCartAuthentication
                                    cartCount={this.state.cartCount}
                                    orderCount={this.state.orderCount}
                                    handleClick={() => this.showLightBox()}
                                />
                            )}
                        </div>
                        {window.localStorage.getItem('user_stype') && <Menu />}
                    </div>
                </nav>
                <div id='shadowNav'></div>
            </div>
        );
    }
}

export default withTranslation()(MenuComponent);
