import React, {Component} from 'react';
import ButtonComponent from '../../../shared/button/components/ButtonComponent';
import '../style/SelectCollection.scss';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import axios from 'axios';
import LoadingComponent from '../../../shared/loading/components/LoadingComponent';
import {withRouter, Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import checkCircle from "../images/check-circle.svg";
import iconError from "../../../../imgs/icon-error.svg";

class SelectCollectionBox extends Component {
    // t('ORDER NOW', 'ORDER NOW')
    state = {
        collections: [],
        loading: true,
        noCollections: false,
        pricelist: 0,
        collectionPricelists: [],
        formSubmitted: false,
        isSubmitting: false,
        emailError: "",
        nameError: "",
        storeNameError: "",
        messageError: "",
        sendError: [],
    };

    getCollections() {
        axios
            .get(`${process.env.REACT_APP_NEW_API_URL}/v1/sellers/${this.props.seller}/short-collections`, this.headers)
            .then((response) => {
                this.setState({
                    collections: response.data,
                    loading: false,
                    collectionPricelists: response.data.map((collection) => {
                        return {
                            id: collection.id,
                            pricelist_id: 0,
                        };
                    }),
                });
                if (response.data.length === 0) {
                    this.setState({ noCollections: true });
                }
            });
    }

    validateFields = (name, email, storeName, message, userSType) => {
        let isValid = true;

        this.setState({
            nameError: "",
            emailError: "",
            storeNameError: "",
            messageError: ""
        });

        if (userSType !== "buyer") {
            if (!email) {
                this.setState({ emailError: "Email address is required" });

                isValid = false;
            } else if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase()))) {
                this.setState({ emailError: "Invalid email address" });

                isValid = false;
            }

            if (!name) {
                this.setState({ nameError: "Name is required" });

                isValid = false;
            }

            if (!storeName) {
                this.setState({ storeNameError: "Store name is required" });

                isValid = false;
            }
        }

        if (!message) {
            this.setState({ messageError: "Message is required" });

            isValid = false;
        }

        return isValid;
    };

    sendForm = async () => {
        const userSType = localStorage.getItem("user_stype");
        const sellerId = this.props.id;

        const nameElement = document.getElementById("name");
        const emailElement = document.getElementById("email");
        const storeNameElement = document.getElementById("store_name");
        const messageElement = document.getElementById("message");

        const name = nameElement ? nameElement.value : "";
        const email = emailElement ? emailElement.value : "";
        const storeName = storeNameElement ? storeNameElement.value : "";
        const message = messageElement ? messageElement.value : "";

        if (!this.validateFields(name, email, storeName, message, userSType)) {
            return;
        }

        const headers = {
            "X-Account-Hash": localStorage.getItem("xAccountHash"),
            "Content-Type": "application/json",
        };

        let payload = { message };

        if (userSType === "buyer") {
            payload = { ...payload, seller_id: sellerId || null };
        } else {
            payload = {
                ...payload,
                name,
                email,
                store_name: storeName,
                seller_id: sellerId || null,
            };
        }
        this.setState({ isSubmitting: true });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_NEW_API_URL}/v2/form-submissions/brand-makeabuy-no-collection`,
                payload,
                { headers }
            );

            this.setState({ formSubmitted: true, isSubmitting: false });
        } catch (error) {
            this.setState({ isSubmitting: false, sendError: error.response });
        }
    };

    componentDidMount() {
        this.getCollections();
    }

    navigateToCollection(collectionId) {
        this.props.history.push(`/${this.props.seller}/collections/${collectionId}`);
    }

    getPricelistId(collectionId) {

        const pricelistStoraged = Number(localStorage.getItem('user.pricelist'));

        if (this.state.collectionPricelists && this.state.collectionPricelists.length > 0) {
            const pricelistSelected = this.state.collectionPricelists.find((collectionPricelist) => collectionPricelist.id === collectionId).pricelist_id
            if (pricelistSelected !== 0) {
                return pricelistSelected
            } else {
                return pricelistStoraged ? pricelistStoraged : 0
            }
        }
    }

    render() {
        const { formSubmitted, isSubmitting, emailError, sendError, nameError, storeNameError, messageError } = this.state;
        if (this.state.loading) {
            return <LoadingComponent />;
        }

        const { t } = this.props;

        const pricelistStoraged = Number(localStorage.getItem('user.pricelist'));

        const userSType = localStorage.getItem("user_stype");

        return (
            <div className='select-collection'>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>{t(`${this.props.name} | Collections`)}</title>
                    <meta name='keywords' content='collection, brands, fashion, buy, sell' />
                    <meta http-equiv='content-language' content='en' />
                    <meta
                        name='description'
                        content="Explore our exclusive fashion collections, with limited editions of unique pieces that you won't find anywhere else."
                    />
                </Helmet>
                {this.state.collections.map((collection, index) => {
                    return (
                        <div className='collection-box' key={index}>
                            <div className='slider-wrapper'>
                                <div
                                    key={index}
                                    className='image-wrapper'
                                    onClick={() => this.props.history.push(`/${this.props.seller}/collections/${collection.id}?pricelist=${this.getPricelistId(collection.id) || ""}`)}
                                >
                                    <img src={collection.list_image} alt={collection.name} />
                                </div>
                            </div>
                            <div className='collection-name'>
                                <div className='name-container'>
                                    <p>{collection.name}</p>
                                </div>
                                {collection.pricelists && collection.pricelists.length > 0 ? (
                                    <div>
                                        <select
                                            value={this.getPricelistId(collection.id) !== 0 ? this.getPricelistId(collection.id) : pricelistStoraged}
                                            onChange={(e) => {
                                                localStorage.setItem("user.pricelist", e.target.value);
                                                this.setState({
                                                    collectionPricelists: [
                                                        {
                                                            id: collection.id,
                                                            pricelist_id: Number(e.target.value),
                                                        },
                                                        ...this.state.collectionPricelists.filter(
                                                            (item) => item.id !== collection.id
                                                        ),
                                                    ],
                                                })
                                            }}>
                                            <option value=''>Select price list</option>
                                            {collection.pricelists.map((pricelist) => (
                                                <option value={pricelist.id} key={pricelist.id}>
                                                    {pricelist.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : null}
                                <div className='button-container'>
                                    <ButtonComponent
                                        type='navLink'
                                        // disabled={
                                        //     collection.pricelists.find((item) => item.id === pricelistStoraged) ? false :
                                        //     collection.pricelists &&
                                        //     collection.pricelists.length > 0 &&
                                        //     this.state.collectionPricelists.find((item) => item.id === collection.id)
                                        //         .pricelist_id === 0
                                        // }
                                        linkTo={`/${this.props.seller}/collections/${collection.id
                                            }?pricelist=${this.getPricelistId(collection.id)}`}>
                                        {}
                                        {window.localStorage.getItem('user_type') === 'buyer' ? (
                                            <>{t(collection.button, collection.button)}</>
                                        ) : (
                                            <>{t('view collection', 'view collection')}</>
                                        )}
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {this.state.noCollections && (
                    <div className={`no-collection ${!this.props.image ? 'no-image' : ''}`}>
                        {this.props.image && (
                            <div className="container-image">
                                <img src={this.props.image} alt="" />
                            </div>
                        )}
                        <div className="container-right">
                            <div className="box">
                                <p>{t("THIS BRAND CURRENTLY HAS NO COLLECTION AVALIABLE")}</p>
                                <p className="text">
                                    {t(`Want to get in touch with ${this.props.name}?`)}
                                </p>
                                <p className="text">{t("Leave a message below.")}</p>
                            </div>
                            {!formSubmitted ? (
                                <div className="container-form">
                                    {userSType !== "buyer" && (
                                        <>
                                            <div>
                                                <label>Your name</label>
                                                <input type="text" name="name" id="name" required />
                                                {nameError && <span className="error">{nameError}</span>}
                                            </div>
                                            <div>
                                                <label>Your email</label>
                                                <input type="email" name="email" id="email" required />
                                                {emailError && (
                                                    <span className="error">{emailError}</span>
                                                )}
                                            </div>
                                            <div>
                                                <label>Your store/e-commerce name</label>
                                                <input
                                                    type="text"
                                                    name="store_name"
                                                    id="store_name"
                                                    required
                                                />
                                                {storeNameError && <span className="error">{storeNameError}</span>}
                                            </div>
                                        </>
                                    )}
                                    <div>
                                        <label>Message</label>
                                        <textarea
                                            placeholder="Hi, we're interested in exploring new brands and products for our store. Could you share your latest line sheet with us?"
                                            rows={5}
                                            name="message"
                                            id="message"
                                        ></textarea>
                                        {messageError && <span className="error">{messageError}</span>}
                                    </div>
                                    <div>
                                        {sendError && sendError.status && sendError.data && (
                                            sendError.status !== 422
                                                ? <span className="error">There was an error sending your message. Please try again later.</span>
                                                : Object.entries(sendError.data.errors).map(([key, errors]) => (
                                                    <span key={key} className="error" style={{ marginBottom: '3px' }}>{errors[0]}</span>
                                                ))
                                        )}
                                    </div>
                                    <button
                                        className="btn-send"
                                        type="button"
                                        onClick={this.sendForm}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Sending..." : "SEND"}
                                    </button>
                                </div>
                            ) : (
                                <div
                                    className="container-success"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={checkCircle} alt="Success" width={77} height={77} />
                                    <p>
                                        {t(`Thank you for reaching out to ${this.props.name}!`)}
                                        <br />
                                        Your message has been successfully sent.
                                        <br />
                                        The brand will get back to you shortly.
                                    </p>
                                    <button onClick={() => (window.location.href = "/brands")}>
                                        FIND OTHER BRANDS
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Link to="/brands" className="back">
                    <span>{t("<< Back to brands", "<< Back to brands")}</span>
                </Link>
            </div>
        );
    }
}

export default withTranslation()(withRouter(SelectCollectionBox));
